import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PushPinIcon from "@mui/icons-material/PushPin";
import Stack from "@mui/material/Stack";
import { isEmpty, isNull } from "lodash";
import { StudentGoal, StudentObjective, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { ListMenuItem, VerticalToggleMode } from "../components/shared/layout/ListMenu";

export type CommonItemFields = {
  type: "goal" | "objective";
  description: string;
  completedBy: string | null;
  isPinned?: boolean;
  isArchived: boolean;
};

export type ProgressMenuItem = Override<
  ListMenuItem<string>,
  {
    displayIndex: number;
    children?: ProgressMenuItem[];
  }
>;

type GoalSelection = { record: StudentGoal; item: ProgressMenuItem };

export type ProgressSelection = {
  goal: GoalSelection;
  objective?: { record: StudentObjective; item: ProgressMenuItem };
};

type MenuItemsResponse = { menuItems: ProgressMenuItem[]; selection?: ProgressSelection };

const EndIcons = ({
  isComplete,
  isPinned,
  togglePinned,
}: {
  isComplete: boolean;
  isPinned: boolean | null;
  togglePinned: () => void;
}) => (
  <Stack direction="row" gap={0.5}>
    {isComplete ? <CheckCircleIcon sx={{ color: "success.main" }} /> : undefined}
    {!isNull(isPinned) && (
      <PushPinIcon
        onClick={e => {
          togglePinned();
          e.stopPropagation();
        }}
        sx={{
          color: isPinned ? "primary.main" : "grey.300",
          "&:hover": { color: "primary.dark" },
        }}
      />
    )}
  </Stack>
);

const getObjectiveMenuItems = (
  objectives: StudentObjective[],
  goal: GoalSelection,
  selectedItemId: string | undefined,
  doesItemMatchFilters: (item: CommonItemFields) => boolean = () => true,
  updateGoal: (goalId: string, update: UpdateGoalBody) => void,
): MenuItemsResponse => {
  let selection: ProgressSelection | undefined = undefined;
  const menuItems = mapExists(objectives, (objective, j) => {
    const objectiveIndex = j + 1;
    if (!doesItemMatchFilters({ type: "objective", ...objective })) return;

    const objectiveItem = {
      key: objective.objectiveId,
      prefix: `${goal.item.displayIndex}.${objectiveIndex}`,
      content: objective.description,
      endIcon: (
        <EndIcons
          isComplete={!!objective.completedAt}
          isPinned={objective.isPinned}
          togglePinned={() =>
            updateGoal(goal.record.goalId, {
              objectives: [{ objectiveId: objective.objectiveId, isPinned: !objective.isPinned }],
            })
          }
        />
      ),
      displayIndex: objectiveIndex,
      canSelect: true,
    };

    if (objective.objectiveId === selectedItemId)
      selection = { goal, objective: { record: objective, item: objectiveItem } };

    return objectiveItem;
  });

  return { menuItems, selection };
};

export const getProgressMenuItems = (
  goals: StudentGoal[],
  selectedItemId: string | undefined,
  doesItemMatchFilters: (item: CommonItemFields) => boolean = () => true,
  updateGoal: (goalId: string, update: UpdateGoalBody) => void,
  { goalMode = "interactive" }: { goalMode?: "interactive" | "static" } = {},
): MenuItemsResponse => {
  let selection: ProgressSelection | undefined = undefined;

  let goalVerticalToggleMode: VerticalToggleMode | undefined = undefined;
  switch (goalMode) {
    case "interactive":
      goalVerticalToggleMode = "children";
      break;
    case "static":
      goalVerticalToggleMode = "content";
      break;
  }

  const menuItems = goals.flatMap((goal, i) => {
    const goalItem: ProgressMenuItem = {
      key: goal.goalId,
      prefix: `${i + 1}`,
      content: goal.description,
      endIcon: (
        <EndIcons
          isComplete={!!goal.completedAt}
          isPinned={goalMode === "static" ? null : goal.isPinned}
          togglePinned={() => updateGoal(goal.goalId, { isPinned: !goal.isPinned })}
        />
      ),
      displayIndex: i + 1,
      verticalToggleMode: goalVerticalToggleMode,
      disableSelect: goalMode === "static",
    };
    const goalSelection = { record: goal, item: goalItem };

    const { menuItems: objectiveItems, selection: objectiveSelection } = getObjectiveMenuItems(
      goal.objectives,
      goalSelection,
      selectedItemId,
      doesItemMatchFilters,
      updateGoal,
    );
    if (objectiveSelection) selection = objectiveSelection;

    if (!doesItemMatchFilters({ type: "goal", ...goal }) && !isEmpty(goal.objectives) && isEmpty(objectiveItems))
      return [];

    if (goal.goalId === selectedItemId) selection = { goal: goalSelection };
    return [{ ...goalItem, children: objectiveItems }];
  });

  return { menuItems, selection };
};

export const resolveObjectiveCompletionToggleUpdate = (
  objectiveId: string,
  isCompleted: boolean,
  parentGoal: StudentGoal,
): UpdateGoalBody => {
  const isParentCompleted = !!parentGoal.completedAt;
  const allObjectivesCompleted =
    isCompleted && parentGoal.objectives.every(o => o.objectiveId === objectiveId || !!o.completedAt);

  return {
    // ensure goal status matches all child objectives
    isCompleted: isParentCompleted !== allObjectivesCompleted ? isCompleted : undefined,
    objectives: [{ objectiveId, isCompleted }],
  };
};
