import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { pick } from "lodash";
import { DateTime } from "luxon";
import AutoCompleteInput, { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { ALL_APPROVAL_STATUSES, APPROVAL_STATUS_NAMES } from "@parallel/vertex/enums/calendar.enums";
import { searchIndirectTimeSchema } from "@parallel/vertex/types/calendar/time.types";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import IndirectTimeList from "@/components/calendar/time/IndirectTimeList";
import TaskTypeInput from "@/components/calendar/time/TaskTypeInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

type TimeSearchParams = {
  provider: SelectOption | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  taskType: SelectOption | null;
  approvalStatus: SelectOption | null;
};

const TimeScreen = () => {
  const {
    apiStore: { userApi },
    authStore: { currentUser, timezone },
  } = useContext(StoreContext);

  const today = DateTime.local({ zone: timezone });
  const [searchParams, setSearchParams] = useState<TimeSearchParams>({
    provider: null,
    startDate: today.minus({ days: 7 }),
    endDate: today,
    taskType: null,
    approvalStatus: null,
  });
  const searchQuery = searchIndirectTimeSchema.safeParse({
    ...pick(searchParams, "startDate", "endDate"),
    userIds: searchParams.provider ? [searchParams.provider.key] : undefined,
    startTime: searchParams.startDate ? toLocalDate(searchParams.startDate, timezone) : null,
    endTime: searchParams.endDate ? toLocalDate(searchParams.endDate, timezone).endOf("day") : null,
    taskTypeId: searchParams.taskType?.key,
    approvalStatus: searchParams.approvalStatus?.key,
  })?.data;

  if (currentUser?.userType !== "ADMIN") return <NotFoundScreen />;

  const header = (
    <Stack direction="row" justifyContent="space-between" alignItems="center" p={3} pb={2}>
      <Stack direction="row" alignItems="center" gap={5}>
        <Typography variant="h1">Indirect Time Tracking</Typography>
        <Box width={300}>
          <AutoCompletePageSearchInput
            label="Provider"
            search={keyword => userApi.searchProviders({ keyword })}
            getOption={p => ({ key: p.userId, label: p.fullName })}
            selected={searchParams.provider}
            onSelect={provider => setSearchParams({ ...searchParams, provider })}
          />
        </Box>
      </Stack>
      <Stack direction="row" gap={3}>
        <Box width={150}>
          <DatePicker
            label="Start Date"
            value={searchParams.startDate}
            onChange={d => setSearchParams({ ...searchParams, startDate: d ? toLocalDate(d, timezone) : null })}
          />
        </Box>
        <Box width={150}>
          <DatePicker
            label="End Date"
            value={searchParams.endDate}
            onChange={d => setSearchParams({ ...searchParams, endDate: d ? toLocalDate(d, timezone) : null })}
          />
        </Box>
        <Box width={250}>
          <TaskTypeInput
            selected={searchParams.taskType}
            onSelect={taskType => setSearchParams({ ...searchParams, taskType })}
          />
        </Box>
        <Box width={180}>
          <AutoCompleteInput
            label="Approval Status"
            options={ALL_APPROVAL_STATUSES.map(key => ({ key, label: APPROVAL_STATUS_NAMES[key] }))}
            selected={searchParams.approvalStatus}
            onSelect={approvalStatus => setSearchParams({ ...searchParams, approvalStatus })}
          />
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <PrimaryLayout headerContent={header}>
      <IndirectTimeList searchQuery={searchQuery} />
    </PrimaryLayout>
  );
};

export default TimeScreen;
