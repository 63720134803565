import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { StudentGoal, StudentObjective, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import { StoreContext } from "@/stores";

const GoalDisplay = ({
  goal,
  goalIndex,
  studentId,
  onEdit,
  onObjectiveSelect,
  onGoalUpdated,
}: {
  goal: StudentGoal;
  goalIndex: number;
  studentId: string;
  onEdit: () => void;
  onObjectiveSelect: (objective: StudentObjective) => void;
  onGoalUpdated: (g: StudentGoal) => void;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const updateGoal = (update: UpdateGoalBody) =>
    progressApi.updateStudentGoal(studentId, goal.goalId, update).then(onGoalUpdated);

  const setGoalCompleted = (isCompleted: boolean) =>
    updateGoal({
      isCompleted,
      objectives: mapExists(
        goal.objectives,
        o => !!o.completedAt !== isCompleted && { objectiveId: o.objectiveId, isCompleted },
      ),
    });

  const toggleGoalArchived = () => {
    const isArchived = !goal.isArchived;
    return updateGoal({
      isArchived,
      objectives: mapExists(
        goal.objectives,
        o => o.isArchived !== isArchived && { objectiveId: o.objectiveId, isArchived },
      ),
    });
  };

  const isCompleted = !!goal.completedAt;

  return (
    <FullStack>
      <Stack pb={2} gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Goal {goalIndex}.</Typography>

          <Stack direction="row" gap={1}>
            <ProcessButton
              process={toggleGoalArchived}
              startIcon={goal.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
              key="archive"
            >
              {goal.isArchived ? "Unarchive" : "Archive"}
            </ProcessButton>
            {!goal.isArchived &&
              (isCompleted ? (
                <ProcessButton process={() => setGoalCompleted(false)} startIcon={<LockOpenIcon />} key="re-open">
                  Re-Open
                </ProcessButton>
              ) : (
                <>
                  <ProcessButton
                    process={() => setGoalCompleted(true)}
                    startIcon={<CheckCircleOutlineIcon />}
                    key="complete"
                  >
                    Complete
                  </ProcessButton>
                  <Button onClick={onEdit} startIcon={<EditIcon />}>
                    Edit
                  </Button>
                </>
              ))}
          </Stack>
        </Stack>

        <Typography>{goal.description}</Typography>
      </Stack>

      {goal.objectives.map((objective, i) => (
        <Stack py={2} gap={1} key={objective.objectiveId}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Link onClick={() => onObjectiveSelect(objective)} sx={{ cursor: "pointer", textDecoration: "none" }}>
              <Typography variant="h3" sx={{ color: "primary.main" }}>
                {toTitleCase(objective.category)} Objective {goalIndex}.{i + 1}
              </Typography>
            </Link>

            <ObjectiveActions objective={objective} studentId={studentId} goal={goal} onGoalUpdated={onGoalUpdated} />
          </Stack>

          <Typography variant="body1">{objective.description}</Typography>
        </Stack>
      ))}
    </FullStack>
  );
};

export default GoalDisplay;
