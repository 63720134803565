import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CSSObject, styled } from "@mui/material/styles";

export const GreyBox = styled(Box)({
  backgroundColor: "#f5f5f5",
  borderRadius: "6px",
  padding: "12px 24px",
  margin: "0 -12px",
});

const fullStyles: CSSObject = {
  width: "100%",
  height: "100%",
  position: "relative",
};

export const FullBox = styled(Box)(fullStyles);

export const FullCenterBox = styled(FullBox)({ display: "grid", placeItems: "center" });

export const FullStack = styled(Stack)(fullStyles);

export const FullGrid = styled(Grid2)(fullStyles);
