import { useContext } from "react";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import { TaskTypeCategory } from "@parallel/vertex/types/calendar/time.types";
import AutoCompleteFetchInput from "@/components/shared/input/AutoCompleteFetchInput";
import { StoreContext } from "@/stores";

const TaskTypeInput = ({
  providerId,
  selected,
  onSelect,
}: {
  providerId?: string;
  selected: SelectOption | null;
  onSelect: (s: SelectOption | null, category?: TaskTypeCategory) => void;
}) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);
  return (
    <AutoCompleteFetchInput
      label="Task Type"
      params={{ providerId }}
      fetchOptions={fetchParams =>
        calendarApi.searchIndirectTimeTypeCategories(fetchParams).then(categories =>
          categories.flatMap(category =>
            category.types.map(type => ({
              key: type.taskTypeId,
              label: type.title,
              groupName: TYPE_CATEGORY_CONFIG[category.value].title,
            })),
          ),
        )
      }
      selected={selected}
      onSelect={selected => {
        const [category] =
          Object.entries(TYPE_CATEGORY_CONFIG).find(([_, config]) => config.title === selected?.groupName) || [];
        onSelect(selected, category as TaskTypeCategory | undefined);
      }}
    />
  );
};

export default TaskTypeInput;
