import {
  CreateReportBody,
  CreateReportResponse,
  ReportStudent,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { SearchStudentQuery } from "@parallel/vertex/types/user/student.types";
import { BaseAPI } from "@/api/base.api";

export class ReportAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public searchEligibleReportStudents = (params: SearchStudentQuery): Promise<PaginatedResult<ReportStudent>> =>
    this.instance.get("assessment/report/eligible-students", { params }).then(r => r.data);

  public createReport = (body: CreateReportBody): Promise<CreateReportResponse> =>
    this.instance.post("assessment/report", body).then(r => r.data);
}
