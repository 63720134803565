import { ReactElement, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { StatusIcon, useAsyncProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { FullBox, FullGrid, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { CommonItemFields, getProgressMenuItems } from "@parallel/polygon/util/progress.util";
import { StudentGoal, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import GoalDisplay from "@/components/progress/GoalDisplay";
import ObjectiveDisplay from "@/components/progress/ObjectiveDisplay";
import ObjectiveMetricDisplay from "@/components/progress/ObjectiveMetricDisplay";
import SearchTextInput from "@/components/shared/input/SearchTextInput";
import { StoreContext } from "@/stores";

const ProgressDisplayLayout = ({
  studentId,
  goals,
  setGoals,
  headerAction,
  onEditGoal,
  appointmentDetails,
  isReadOnly,
}: {
  studentId: string;
  appointmentId?: string;
  goals: StudentGoal[];
  setGoals: (goals: StudentGoal[]) => void;
  headerAction: { text: string; icon: ReactElement; perform: () => void };
  onEditGoal: (editing: { goal: StudentGoal; index: number }) => void;
  appointmentDetails?: {
    appointmentId: string;
    isWaived: boolean;
    toggleWaived: () => Promise<void>;
  };
  isReadOnly?: boolean;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const [selectedItemId, setSelectedItemId] = useState<string>(goals[0]?.goalId);

  const [searchString, setSearchString] = useState("");
  const [onlyShowPinned, setOnlyShowPinned] = useState(goals.some(g => g.objectives.some(o => o.isPinned)));
  const [isShowingCompleted, setIsShowingCompleted] = useState(true);
  const [isShowingArchived, setIsShowingArchived] = useState(false);

  const doesItemMatchFilters = (item: CommonItemFields) =>
    (!searchString || item.description.toLowerCase().includes(searchString.toLowerCase())) &&
    (!onlyShowPinned || !!item.isPinned) &&
    (isShowingCompleted || !item.completedBy) &&
    (isShowingArchived || !item.isArchived);

  const goalUpdated = (updated: StudentGoal) => {
    const newGoals = goals.map(g => (g.goalId === updated.goalId ? updated : g));
    setGoals(newGoals);
  };

  const updateGoal = (goalId: string, update: UpdateGoalBody) => {
    progressApi.updateStudentGoal(studentId, goalId, update).then(goalUpdated);
  };

  const { menuItems, selection } = getProgressMenuItems(goals, selectedItemId, doesItemMatchFilters, updateGoal);

  const { status: progressWaivedStatus, perform: toggleProgressWaived } = useAsyncProcessStatus(
    async () => appointmentDetails && appointmentDetails.toggleWaived(),
  );

  let primaryContent: ReactElement | undefined = undefined;
  if (selection) {
    if (!selection.objective) {
      const goalSelection = { goal: selection.goal.record, index: selection.goal.item.displayIndex };
      primaryContent = (
        <GoalDisplay
          goal={goalSelection.goal}
          goalIndex={goalSelection.index}
          studentId={studentId}
          onEdit={() => onEditGoal(goalSelection)}
          onObjectiveSelect={objective => setSelectedItemId(objective.objectiveId)}
          onGoalUpdated={goalUpdated}
        />
      );
    } else {
      const listIndex = {
        objective: selection.objective.item.displayIndex,
        goal: selection.goal.item.displayIndex,
      };
      primaryContent = appointmentDetails ? (
        <ObjectiveMetricDisplay
          objective={selection.objective.record}
          listIndex={listIndex}
          goal={selection.goal.record}
          studentId={studentId}
          appointmentId={appointmentDetails.appointmentId}
          onGoalUpdated={goalUpdated}
          onMetricWritten={() => appointmentDetails?.isWaived && toggleProgressWaived(null)}
          isReadOnly={isReadOnly}
          key={selection.objective.record.objectiveId}
        />
      ) : (
        <ObjectiveDisplay
          objective={selection.objective.record}
          listIndex={listIndex}
          goal={selection.goal.record}
          studentId={studentId}
          onGoalUpdated={goalUpdated}
        />
      );
    }
  }

  return (
    <FullStack pt={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={2}>
        <Stack direction="row" gap={4} alignItems="center">
          <SearchTextInput value={searchString} setValue={setSearchString} width={300} />
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={onlyShowPinned} onChange={e => setOnlyShowPinned(e.target.checked)} />}
              label="Pinned Only"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isShowingCompleted} onChange={e => setIsShowingCompleted(e.target.checked)} />}
              label="Show Completed"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isShowingArchived} onChange={e => setIsShowingArchived(e.target.checked)} />}
              label="Show Archived"
            />
          </FormGroup>
          {appointmentDetails && (
            <Stack direction="row" gap={1} alignItems="center">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appointmentDetails.isWaived}
                      onChange={e => toggleProgressWaived(e.target.checked)}
                      disabled={isReadOnly}
                    />
                  }
                  label="No data collected this session"
                />
              </FormGroup>
              {progressWaivedStatus && <StatusIcon status={progressWaivedStatus} />}
            </Stack>
          )}
        </Stack>

        <Button onClick={headerAction.perform} startIcon={headerAction.icon}>
          {headerAction.text}
        </Button>
      </Stack>

      <Box sx={{ width: "100%", height: "calc(100vh - 100px)", overflowY: "hidden" }}>
        <FullGrid container sx={{ height: "100%", overflowY: "hidden" }}>
          <Grid xs={4} sx={{ height: "100%", overflowY: "auto", borderRight: 1, borderColor: "grey.200", pr: 2 }}>
            <ListMenu
              nested
              items={menuItems}
              selectedKey={selectedItemId}
              onSelect={id => id && setSelectedItemId(id)}
            />
          </Grid>

          <Grid xs={8} pl={2} sx={{ height: "100%", overflowY: "auto" }}>
            <FullStack alignItems="center">
              <FullBox sx={{ height: "100%", overflowY: "auto", pr: 4, maxWidth: "100%" }}>{primaryContent}</FullBox>
            </FullStack>
          </Grid>
        </FullGrid>
      </Box>
    </FullStack>
  );
};

export default ProgressDisplayLayout;
