import { useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { pick } from "lodash";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { CreateSlpaBody, ExtendedSlpaUser, createSlpaBodySchema } from "@parallel/vertex/types/user/slpa.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import NameInput from "@/components/user/input/NameInput";
import { StoreContext } from "@/stores";

type CreateSlpaParams = Partial<Omit<CreateSlpaBody, "supervisorIds" | "districtId">> & {
  supervisors: SelectOption[];
  district: SelectOption | null;
};

const SlpaForm = ({
  editing,
  onWrite,
  onClose,
}: {
  editing?: ExtendedSlpaUser;
  onWrite: () => void;
  onClose: () => void;
}) => {
  const {
    apiStore: { institutionApi, userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const initialDistrictOption =
    editing?.districtId && editing?.districtName ? { key: editing.districtId, label: editing.districtName } : null;

  const defaultSupervisor =
    currentUser?.userType === "PROVIDER" ? { key: currentUser?.userId, label: currentUser?.fullName } : null;
  const initialSupervisorOptions =
    editing?.supervisorIds && editing?.supervisorNames
      ? editing.supervisorIds.map((id, index) => ({ key: id, label: editing.supervisorNames![index] }))
      : filterExists([defaultSupervisor]);

  const [params, setParams] = useState<CreateSlpaParams>({
    ...pick(editing, "firstName", "lastName"),
    email: editing?.email || undefined,
    district: initialDistrictOption,
    supervisors: initialSupervisorOptions,
  });

  const formContent = (
    <>
      <NameInput params={params} setParams={p => setParams({ ...params, ...p })} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
      <AutoCompletePageSearchInput
        label="District"
        search={name => institutionApi.searchInstitutions({ name, institutionType: "DISTRICT" })}
        getOption={i => ({ key: i.institutionId, label: i.salesforceName })}
        selected={params.district}
        onSelect={district => setParams({ ...params, district })}
      />
      {currentUser?.userType === "ADMIN" && (
        <AutoCompletePageSearchInput
          label="Supervisors"
          search={keyword => userApi.searchProviders({ keyword, providerType: "SLP" })}
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={params.supervisors}
          onSelect={supervisors => setParams({ ...params, supervisors })}
        />
      )}
      {!editing && (
        <FormControlLabel
          label="Send Welcome Email"
          control={
            <Checkbox
              checked={params.sendWelcomeEmail}
              onChange={e => setParams({ ...params, sendWelcomeEmail: e.target.checked })}
            />
          }
        />
      )}
    </>
  );

  const validate = (params: CreateSlpaParams) =>
    createSlpaBodySchema.safeParse({
      ...params,
      districtId: params.district?.key,
      supervisorIds: params.supervisors.map(s => s.key),
    })?.data;

  const onSubmit = async (body: CreateSlpaBody) => {
    const welcomeEmailFailed = editing
      ? await userApi.updateSlpa(editing.userId, body).then(() => undefined)
      : await userApi.createSlpa(body).then(r => r.welcomeEmailFailed);

    onWrite();

    if (welcomeEmailFailed) {
      return {
        warning: `Successfully created SLPA, but failed to send welcome email`,
      };
    }
  };

  return (
    <SubmitForm
      recordName="slpa"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default SlpaForm;
