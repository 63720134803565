import type { Availability } from "@prisma/client";
import { z } from "zod";
import { recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { ExtendedCalendarItem, timeRangeFields, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type ExtendedAvailability = ExtendedCalendarItem<Availability> & {
  userName: string;
  recurrence?: ExtendedRecurrence;
};

const availabilityBody = z.object({
  userId: z.string().uuid().optional(),
  ...timeRangeFields,
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export const createAvailabilityBodySchema = availabilityBody.refine(...timeRangeRefine);

export type CreateAvailabilityBody = z.infer<typeof createAvailabilityBodySchema>;

export const updateAvailabilityBodySchema = availabilityBody.partial().refine(...timeRangeRefine);

export type UpdateAvailabilityBody = z.infer<typeof updateAvailabilityBodySchema>;

export const deleteAvailabilityBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteAvailabilityBody = z.infer<typeof deleteAvailabilityBodySchema>;
