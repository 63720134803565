import { useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Status, StatusIcon } from "../../shared/input/status.input";

const NoteInput = ({
  value,
  writeValue,
  status,
  disabled,
  compact,
}: {
  value: string;
  writeValue: (s: string) => Promise<unknown>;
  status?: Status;
  disabled?: boolean;
  compact?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(value);

  const attemptWrite = (newValue: string) => writeValue(newValue).catch(() => setInputValue(value));

  return (
    <Stack gap={1} alignItems="center">
      <TextField
        label="Objective Note"
        fullWidth
        multiline
        rows={compact ? 3 : 12}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onBlur={() => attemptWrite(inputValue)}
        disabled={disabled}
      />
      {status && !compact && <StatusIcon status={status} />}
    </Stack>
  );
};

export default NoteInput;
