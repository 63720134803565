import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import {
  AppointmentEventSummary,
  ExtendedFrontendEvent,
  SearchAppointmentEventsQuery,
} from "@parallel/vertex/types/calendar/appointment.event.types";
import {
  AppointmentCancelReason,
  AppointmentDetails,
  AppointmentType,
  BookAppointmentBody,
  ExtendedAppointment,
  ExtendedAppointmentUser,
  SearchAppointmentTypeQuery,
  UpdateAppointmentBody,
  UpdateAppointmentUserBody,
} from "@parallel/vertex/types/calendar/appointment.types";
import {
  CreateAvailabilityBody,
  ExtendedAvailability,
  UpdateAvailabilityBody,
} from "@parallel/vertex/types/calendar/availability.types";
import {
  CalendarItems,
  SearchCalendarQuery,
  SearchUserCalendarItemQuery,
} from "@parallel/vertex/types/calendar/calendar.types";
import {
  BulkUpdateIndirectTimeBody,
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  IndirectTimeTypeCategory,
  SearchIndirectTimeTypesQuery,
  UpdateIndirectTimeBody,
} from "@parallel/vertex/types/calendar/time.types";
import { DateTimeRange, PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";

export class CalendarAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public searchCalendarItems = (query: SearchCalendarQuery): Promise<CalendarItems> =>
    this.instance.get("calendar", { params: query }).then(r => r.data);

  public getAppointment = (appointmentId: string): Promise<ExtendedAppointment> =>
    this.instance.get(`calendar/appointment/${appointmentId}`).then(r => r.data);

  public bookAppointment = (body: BookAppointmentBody): Promise<ExtendedAppointment> =>
    this.instance.post("calendar/appointment", body).then(r => r.data);

  public updateAppointment = (appointmentId: string, body: UpdateAppointmentBody): Promise<ExtendedAppointment> =>
    this.instance.put(`calendar/appointment/${appointmentId}`, body).then(r => r.data);

  public deleteAppointment = (appointmentId: string, recurrenceEditMode?: RecurrenceEditMode): Promise<void> =>
    this.instance.delete(`calendar/appointment/${appointmentId}`, { data: { recurrenceEditMode } });

  public updateAppointmentUser = (
    appointmentId: string,
    userId: string,
    body: UpdateAppointmentUserBody,
  ): Promise<ExtendedAppointmentUser> =>
    this.instance.put(`calendar/appointment/${appointmentId}/user/${userId}`, body).then(r => r.data);

  public getAppointmentDetails = (appointmentId: string): Promise<AppointmentDetails> =>
    this.instance.get(`calendar/appointment/${appointmentId}/details`).then(r => r.data);

  public getAvailability = (availabilityId: string): Promise<ExtendedAvailability> =>
    this.instance.get(`calendar/availability/${availabilityId}`).then(r => r.data);

  public createAvailability = (body: CreateAvailabilityBody): Promise<ExtendedAvailability> =>
    this.instance.post("calendar/availability", body).then(r => r.data);

  public updateAvailability = (availabilityId: string, body: UpdateAvailabilityBody): Promise<ExtendedAvailability> =>
    this.instance.put(`calendar/availability/${availabilityId}`, body).then(r => r.data);

  public deleteAvailability = (availabilityId: string, recurrenceEditMode?: RecurrenceEditMode): Promise<void> =>
    this.instance.delete(`calendar/availability/${availabilityId}`, { data: { recurrenceEditMode } });

  public getIndirectTime = (timeId: string): Promise<ExtendedTimeEntry> =>
    this.instance.get(`calendar/time/${timeId}`).then(r => r.data);

  public searchIndirectTime = (query: SearchUserCalendarItemQuery): Promise<PaginatedResult<ExtendedTimeEntry>> =>
    this.instance.get("calendar/time", { params: query }).then(r => r.data);

  public createIndirectTime = (body: CreateIndirectTimeBody): Promise<ExtendedTimeEntry> =>
    this.instance.post("calendar/time", body).then(r => r.data);

  public updateIndirectTime = (timeId: string, body: UpdateIndirectTimeBody): Promise<ExtendedTimeEntry> =>
    this.instance.put(`calendar/time/${timeId}`, body).then(r => r.data);

  public bulkUpdateIndirectTime = (body: BulkUpdateIndirectTimeBody): Promise<ExtendedTimeEntry[]> =>
    this.instance.put("calendar/time", body).then(r => r.data.entries);

  public deleteIndirectTime = (timeId: string): Promise<void> => this.instance.delete(`calendar/time/${timeId}`);

  public searchIndirectTimeTypeCategories = (
    query: SearchIndirectTimeTypesQuery,
  ): Promise<IndirectTimeTypeCategory[]> =>
    this.instance.get("calendar/time/type/category", { params: query }).then(r => r.data.categories);

  public searchAppointmentTypes = (
    params: SearchAppointmentTypeQuery = {},
  ): Promise<PaginatedResult<AppointmentType>> =>
    this.instance.get("calendar/appointment/type", { params }).then(r => r.data);

  public getAllAppointmentCancelReasons = (): Promise<AppointmentCancelReason[]> =>
    this.instance.get("calendar/appointment/cancel-reason").then(r => r.data);

  public getCurrentPayPeriod = (): Promise<DateTimeRange> => this.instance.get("calendar/pay-period").then(r => r.data);

  public getAppointmentEventSummary = (appointmentId: string): Promise<AppointmentEventSummary> =>
    this.instance.get(`calendar/appointment/${appointmentId}/event/summary`).then(r => r.data);

  public searchAppointmentEvents = (
    appointmentId: string,
    params: SearchAppointmentEventsQuery = {},
  ): Promise<PaginatedResult<ExtendedFrontendEvent>> =>
    this.instance.get(`calendar/appointment/${appointmentId}/event`, { params }).then(r => r.data);
}
