import { ReactNode, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { groupBy, isEmpty, isNull, keyBy, map, mapValues, padStart, partition } from "lodash";
import { DateTime } from "luxon";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { ApprovalStatus, ExtendedTimeEntry, SearchIndirectTimeQuery } from "@parallel/vertex/types/calendar/time.types";
import { PaginateState, PaginatedResult } from "@parallel/vertex/types/shared.types";
import { timeRangeString, toUtcDate } from "@parallel/vertex/util/datetime.util";
import ApprovalControls, { PendingApprovalControls } from "@/components/shared/input/ApprovalControls";
import PaginationControl from "@/components/shared/navigation/PaginationControl";
import { StoreContext } from "@/stores";

const CONTENT_COLUMN_KEYS = ["typeCell", "providerCell", "relationCell", "timeCell"] as const;

const TypeCell = ({ timeEntry }: { timeEntry: ExtendedTimeEntry }) => {
  return (
    <FullStack justifyContent="center">
      <Typography>{timeEntry.taskType.title}</Typography>
      {timeEntry.description && (
        <Typography variant="subtitle2" noWrap>
          {timeEntry.description}
        </Typography>
      )}
    </FullStack>
  );
};

const TextBadge = ({ children }: { children: string }) => (
  <Box bgcolor="grey.200" borderRadius={4} px={1} py={0.5}>
    <Typography variant="subtitle2">{children}</Typography>
  </Box>
);

const RelationCell = ({ timeEntry }: { timeEntry: ExtendedTimeEntry }) => {
  return (
    <FullStack direction="row" alignItems="center" gap={0.5}>
      {timeEntry.campus ? (
        <TextBadge>{timeEntry.campus.salesforceName}</TextBadge>
      ) : (
        timeEntry.students.map(s => <TextBadge key={s.userId}>{s.fullName}</TextBadge>)
      )}
    </FullStack>
  );
};

const TimeCell = ({ timeEntry }: { timeEntry: ExtendedTimeEntry }) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);
  const { hours, minutes } = timeEntry.endTime.diff(timeEntry.startTime, ["hours", "minutes"]);
  return (
    <FullStack direction="row" alignItems="center">
      <Typography width={200}>{timeRangeString(timeEntry, timezone, { withZone: true })}</Typography>
      <Typography fontWeight="bold">
        {hours}:{padStart(minutes.toString(), 2, "0")}
      </Typography>
    </FullStack>
  );
};

const ListRow = (props: {
  typeCell: ReactNode;
  providerCell?: ReactNode;
  relationCell: ReactNode;
  timeCell: ReactNode;
  approvalCell: ReactNode;
  toggleStatus?: "checked" | "indeterminate" | null;
  onToggle: (isSelected: boolean) => void;
  isHeader?: boolean;
}) => {
  const { approvalCell, toggleStatus, onToggle, isHeader } = props;
  const isRowClickable = !isHeader && !isNull(toggleStatus);

  const contentColumnCount = CONTENT_COLUMN_KEYS.filter(k => props[k]).length;
  const contentColumnWidth = percentString(1 / contentColumnCount);

  return (
    <Stack
      direction="row"
      height={55}
      py={0.5}
      px={1}
      borderBottom={isHeader ? 0 : 1}
      borderColor="grey.200"
      sx={{ cursor: isRowClickable ? "pointer" : undefined }}
      onClick={isRowClickable ? () => onToggle(!toggleStatus) : undefined}
    >
      <Stack direction="row" alignItems="center" sx={{ height: "100%", width: 70, flex: "none" }}>
        {!isNull(toggleStatus) && (
          <Checkbox
            checked={!!toggleStatus}
            indeterminate={toggleStatus === "indeterminate"}
            onChange={e => onToggle(e.target.checked)}
          />
        )}
      </Stack>
      <Stack direction="row" sx={{ flex: "1 1 0%" }}>
        {CONTENT_COLUMN_KEYS.map(
          (propKey, i) =>
            props[propKey] && (
              <Stack direction="row" alignItems="center" sx={{ height: "100%", width: contentColumnWidth }} key={i}>
                {props[propKey]}
              </Stack>
            ),
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ width: 140, flex: "none" }}>
        {approvalCell}
      </Stack>
    </Stack>
  );
};

type TimeEntryState = ExtendedTimeEntry & { isSelected: boolean };

const IndirectTimeList = ({ searchQuery }: { searchQuery?: Partial<SearchIndirectTimeQuery> }) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [indirectTimePage, setIndirectTimePage] = useState<PaginatedResult<TimeEntryState>>();
  const [pageState, setPageState] = useState<PaginateState>({ pageSize: 50, offset: 0 });

  useEffect(() => {
    if (!searchQuery) {
      setIndirectTimePage(undefined);
      return;
    }
    calendarApi
      .searchIndirectTime({ ...searchQuery, ...pageState })
      .then(r =>
        setIndirectTimePage({ records: r.records.map(t => ({ ...t, isSelected: false })), totalCount: r.totalCount }),
      );
  }, [searchQuery, pageState]);

  const updateTimeEntryState = (updates: Record<string, Partial<TimeEntryState>>) => {
    if (!indirectTimePage) return;
    setIndirectTimePage({
      ...indirectTimePage,
      records: indirectTimePage.records.map(entry => ({ ...entry, ...updates[entry.timeEntryId] })),
    });
  };

  const showProviderColumn = isEmpty(searchQuery?.userIds);

  let content = <Typography p={2}>Select a valid time range above</Typography>;

  if (indirectTimePage) {
    if (indirectTimePage.totalCount === 0) {
      content = <Typography p={2}>No Matches Found</Typography>;
    } else {
      const entriesByDay = groupBy(indirectTimePage.records, t => toUtcDate(t.startTime).toISO());
      content = (
        <FullStack gap={2}>
          {map(entriesByDay, (entries, dateTimestamp) => (
            <Stack key={dateTimestamp}>
              <Box sx={{ width: "100%", bgcolor: "grey.100", py: 0.5, px: 1 }}>
                {DateTime.fromISO(dateTimestamp, { zone: "utc" }).toLocaleString(DateTime.DATE_HUGE)}
              </Box>
              {entries.map(entry => (
                <ListRow
                  typeCell={<TypeCell timeEntry={entry} />}
                  providerCell={showProviderColumn ? <TextBadge>{entry.userName || ""}</TextBadge> : undefined}
                  relationCell={<RelationCell timeEntry={entry} />}
                  timeCell={<TimeCell timeEntry={entry} />}
                  approvalCell={
                    <ApprovalControls
                      approvalStatus={entry.latestApproval?.approvalStatus}
                      onStatusUpdate={approvalStatus =>
                        calendarApi
                          .updateIndirectTime(entry.timeEntryId, { approvalStatus })
                          .then(updated => updateTimeEntryState({ [entry.timeEntryId]: updated }))
                      }
                    />
                  }
                  toggleStatus={entry.isSelected ? "checked" : entry.latestApproval ? undefined : null}
                  onToggle={isSelected => updateTimeEntryState({ [entry.timeEntryId]: { isSelected } })}
                  key={entry.timeEntryId}
                />
              ))}
            </Stack>
          ))}
        </FullStack>
      );
    }
  }

  const [selected, notSelected] = partition(indirectTimePage?.records, "isSelected");

  const bulkApprovalUpdate = async (approvalStatus: ApprovalStatus) => {
    const updates = mapValues(keyBy(selected, "timeEntryId"), () => ({ approvalStatus }));
    const updated = await calendarApi.bulkUpdateIndirectTime(updates);
    updateTimeEntryState(keyBy(updated, "timeEntryId"));
  };

  return (
    <FullStack>
      <Box sx={{ width: "100%", pr: 2, borderBottom: 1, borderColor: "grey.300", fontWeight: "bold" }}>
        <ListRow
          isHeader
          typeCell="Type"
          providerCell={showProviderColumn ? "Provider" : undefined}
          relationCell="Students/Campus"
          timeCell="Time"
          approvalCell={
            isEmpty(selected) ? (
              <></>
            ) : (
              <PendingApprovalControls onStatusUpdate={bulkApprovalUpdate} bulkUpdateCount={selected.length} />
            )
          }
          toggleStatus={isEmpty(selected) ? undefined : isEmpty(notSelected) ? "checked" : "indeterminate"}
          onToggle={isSelected => {
            if (!indirectTimePage) return;
            setIndirectTimePage({
              ...indirectTimePage,
              records: indirectTimePage.records.map(t => (t.latestApproval ? { ...t, isSelected } : t)),
            });
          }}
        />
      </Box>
      <Box sx={{ width: "100%", flex: "1 1 0%", pt: 2, overflowY: "auto", scrollbarGutter: "stable" }}>{content}</Box>
      {indirectTimePage && (
        <PaginationControl pageState={pageState} setPageState={setPageState} totalCount={indirectTimePage.totalCount} />
      )}
    </FullStack>
  );
};

export default IndirectTimeList;
