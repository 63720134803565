import { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { isEmpty, pick } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import {
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  createIndirectTimeBodySchema,
} from "@parallel/vertex/types/calendar/time.types";
import { toArray } from "@parallel/vertex/util/collection.util";
import { isInRange } from "@parallel/vertex/util/datetime.util";
import TaskTypeInput from "@/components/calendar/time/TaskTypeInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import TimeRangeInput from "@/components/shared/input/TimeRangeInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { UserOption } from "@/components/user/input/UserInput";
import { StoreContext } from "@/stores";

type IndirectTimeParams = Partial<
  Omit<CreateIndirectTimeBody, "userId" | "taskTypeId" | "studentUserIds" | "campusId">
> & {
  user: SelectOption | null;
  taskType: SelectOption | null;
  students: SelectOption[];
  campus: SelectOption | null;
};

const MAX_DURATION_HOURS = 8;

const IndirectTimeForm = ({
  editing,
  selectedUser,
  onClose,
}: {
  editing?: ExtendedTimeEntry;
  selectedUser?: UserOption;
  onClose: () => void;
}) => {
  const {
    apiStore: { institutionApi, userApi },
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const [params, setParams] = useState<IndirectTimeParams>({
    ...calendarStore.defaultTimeRange,
    ...editing,
    user:
      selectedUser?.userType === "PROVIDER"
        ? { key: selectedUser.userId, label: selectedUser.fullName }
        : editing?.userName
          ? { key: editing.userId, label: editing.userName }
          : null,
    taskType: editing
      ? {
          key: editing.taskTypeId,
          label: editing.taskType.title,
          groupName: TYPE_CATEGORY_CONFIG[editing.taskType.category]?.title,
        }
      : null,
    students: editing ? editing.students.map(s => ({ key: s.userId, label: s.fullName })) : [],
    campus: editing?.campus ? { key: editing.campus.institutionId, label: editing.campus.salesforceName } : null,
  });

  const [currentTypeCategory, setCurrentTypeCategory] = useState(editing?.taskType.category);
  const currentTypeCategoryConfig = currentTypeCategory ? TYPE_CATEGORY_CONFIG[currentTypeCategory] : undefined;
  if (currentTypeCategoryConfig && params.taskType?.label === "Supervision")
    currentTypeCategoryConfig.student = "required";

  const { currentPayPeriod } = calendarStore;
  const validStartRange = currentPayPeriod
    ? { startTime: currentPayPeriod.startTime, endTime: DateTime.utc() }
    : undefined;

  const formContent = (
    <>
      {currentUser?.userType === "ADMIN" && (
        <AutoCompletePageSearchInput
          label="Provider"
          search={keyword => userApi.searchProviders({ keyword })}
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={params.user}
          onSelect={user => setParams({ ...params, user })}
        />
      )}
      <TaskTypeInput
        providerId={params.user?.key}
        selected={params.taskType}
        onSelect={(taskType, category) => {
          setCurrentTypeCategory(category);
          setParams({ ...params, taskType });
        }}
      />
      {currentTypeCategoryConfig?.student && (
        <AutoCompletePageSearchInput
          label={currentTypeCategoryConfig.student === "required" ? "Student(s)" : "Student (optional)"}
          search={keyword => userApi.searchStudents({ keyword, providerId: params.user?.key })}
          getOption={s => ({ key: s.userId, label: s.fullName })}
          selected={
            currentTypeCategoryConfig.student === "single-optional" ? params.students[0] || null : params.students
          }
          onSelect={selected => setParams({ ...params, students: toArray(selected) })}
        />
      )}
      {currentTypeCategoryConfig?.campus && (
        <AutoCompletePageSearchInput
          label="Campus"
          search={name => institutionApi.searchCampuses({ name, providerId: params.user?.key })}
          getOption={c => ({
            key: c.institutionId,
            label: c.salesforceName,
            groupName: c.district?.salesforceName || "No District",
          })}
          selected={params.campus}
          onSelect={campus => setParams({ ...params, campus })}
        />
      )}
      <TimeRangeInput
        value={pick(params, "startTime", "endTime")}
        onChange={range => setParams({ ...params, ...range })}
        minStart={validStartRange?.startTime}
        maxStart={validStartRange?.endTime}
      />
      <TextField
        multiline
        rows={2}
        label="Description (optional)"
        value={params.description || ""}
        onChange={e => setParams({ ...params, description: e.target.value })}
      />
    </>
  );

  const onSubmit = async (body: CreateIndirectTimeBody) => {
    editing
      ? await calendarStore.updateIndirectTime(editing.timeEntryId, body)
      : await calendarStore.createIndirectTime(body);
    onClose();
  };

  const validate = (params: IndirectTimeParams) => {
    if (currentTypeCategoryConfig?.student === "required" && isEmpty(params.students)) return undefined;
    if (currentTypeCategoryConfig?.campus === "required" && isEmpty(params.campus)) return undefined;
    if (params.startTime && validStartRange && !isInRange(validStartRange, params.startTime)) return undefined;

    if (params.startTime && params.endTime && params.endTime.diff(params.startTime, "hours").hours > MAX_DURATION_HOURS)
      return undefined;

    return createIndirectTimeBodySchema.safeParse({
      ...params,
      userId: params.user?.key,
      taskTypeId: params.taskType?.key,
      studentUserIds: params.students.map(s => s.key),
      campusId: params.campus?.key,
    })?.data;
  };

  return (
    <SubmitForm
      recordName="indirect time"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default observer(IndirectTimeForm);
