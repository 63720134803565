import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const containerStyles = {
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  left: "50%",
  position: "absolute",
  px: 5,
  py: 4,
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const CenterModal = ({
  isOpen,
  onClose,
  minWidth = null,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  minWidth?: number | null;
  children: ReactNode;
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ ...containerStyles, minWidth, maxHeight: "92vh", overflowY: "auto" }}>{children}</Box>
    </Modal>
  );
};

export default CenterModal;
