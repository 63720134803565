import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import CalendarDayView from "@/components/calendar/CalendarDayView";
import CalendarHeader from "@/components/calendar/CalendarHeader";
import CalendarWeekView from "@/components/calendar/CalendarWeekView";
import AppointmentDisplay from "@/components/calendar/appointment/AppointmentDisplay";
import AppointmentForm from "@/components/calendar/appointment/AppointmentForm";
import AvailabilityDisplay from "@/components/calendar/availability/AvailabilityDisplay";
import AvailabilityForm from "@/components/calendar/availability/AvailabilityForm";
import IndirectTimeDisplay from "@/components/calendar/time/IndirectTimeDisplay";
import IndirectTimeForm from "@/components/calendar/time/IndirectTimeForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";
import { CALENDAR_PERIOD_TYPES, CalendarItemType } from "@/stores/calendar.store";
import { useUrlNavigation } from "@/util/router.util";

const CalendarScreen = ({ itemType, userType }: { itemType?: CalendarItemType; userType?: UserType }) => {
  const {
    authStore: { currentUser, timezone },
    calendarStore,
  } = useContext(StoreContext);
  const {
    period,
    fetchStatus,
    currentSelection,
    selectedAppointment,
    selectedAvailability,
    selectedIndirectTime,
    selectedUser,
    baseUrl,
  } = calendarStore;

  const { navigate } = useUrlNavigation();
  const { itemId, userId } = useParams();
  const [query] = useSearchParams();
  const dateParam = query.get("date");
  const periodParam = query.get("period");

  useEffect(() => {
    const periodDateTime = dateParam ? toLocalDate(DateTime.fromISO(dateParam), timezone) : undefined;
    const periodType = CALENDAR_PERIOD_TYPES.find((t?: string) => t === periodParam);

    if (itemId && itemType) {
      calendarStore.loadItem({ itemId, itemType }, periodType);
    } else if (userId && userType) {
      calendarStore.loadSelectedUser({ userId, userType }, periodDateTime, periodType);
    } else {
      calendarStore.loadCurrentUser(periodDateTime, periodType);
    }
  }, [itemId, itemType, userId, userType, dateParam, periodParam]);

  const [createModalType, setCreateModalType] = useState<CalendarItemType>();

  const clearSelectionModal = () => {
    setCreateModalType(undefined);
    if (currentSelection) {
      runInAction(() => (calendarStore.currentSelection = undefined));
      navigate(baseUrl, { keepQuery: true });
    }
  };

  if (fetchStatus === "not-found") return <NotFoundScreen />;

  return (
    <PrimaryLayout
      headerContent={fetchStatus !== "initiating" ? <CalendarHeader onCreate={setCreateModalType} /> : undefined}
    >
      {fetchStatus === "waiting" && currentUser?.userType === "ADMIN" ? (
        <FullCenterBox>
          <Typography variant="body1">Select a User</Typography>
        </FullCenterBox>
      ) : fetchStatus !== "finished" ? (
        <FullCenterBox>
          <CircularProgress />
        </FullCenterBox>
      ) : period.type === "day" ? (
        <CalendarDayView date={period.startTime.plus({ hours: 12 }).setZone(timezone)} />
      ) : period.type === "week" ? (
        <CalendarWeekView date={period.startTime.plus({ hours: 12 }).setZone(timezone)} />
      ) : null}

      <CenterModal isOpen={!!createModalType || !!currentSelection} onClose={clearSelectionModal} minWidth={400}>
        {selectedAppointment && <AppointmentDisplay appointment={selectedAppointment} onClose={clearSelectionModal} />}
        {selectedAvailability && (
          <AvailabilityDisplay availability={selectedAvailability} onClose={clearSelectionModal} />
        )}
        {selectedIndirectTime && <IndirectTimeDisplay time={selectedIndirectTime} onClose={clearSelectionModal} />}
        {createModalType === "appointment" && (
          <AppointmentForm
            editing={selectedAppointment}
            selectedUser={selectedUser || undefined}
            onClose={clearSelectionModal}
          />
        )}
        {createModalType === "availability" && (
          <AvailabilityForm
            editing={selectedAvailability}
            selectedUser={selectedUser || undefined}
            onClose={clearSelectionModal}
          />
        )}
        {createModalType === "indirect-time" && (
          <IndirectTimeForm
            editing={selectedIndirectTime}
            selectedUser={selectedUser || undefined}
            onClose={clearSelectionModal}
          />
        )}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(CalendarScreen);
