import { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EditIcon from "@mui/icons-material/Edit";
import NoteIcon from "@mui/icons-material/Note";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import { keyBy } from "lodash";
import { observer } from "mobx-react-lite";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { StudentAppointmentProgress, StudentGoal } from "@parallel/vertex/types/progress.types";
import CalendarItemSubHeader from "@/components/calendar/CalendarItemSubHeader";
import ProgressDisplayLayout from "@/components/progress/ProgressDisplayLayout";
import MultipleGoalForm from "@/components/progress/form/MultipleGoalForm";
import SingleGoalForm from "@/components/progress/form/SingleGoalForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";

const AppointmentProgressScreen = () => {
  const {
    apiStore: { progressApi },
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);
  const { fetchStatus, selectedAppointment, currentPayPeriod } = calendarStore;

  const [currentStudentId, setCurrentStudentId] = useState<string>();

  const { itemId: appointmentId } = useParams();
  useEffect(() => {
    if (!appointmentId) return;
    calendarStore.loadItem({ itemId: appointmentId, itemType: "appointment" });
  }, [appointmentId]);

  const [studentProgress, setStudentProgress] = useState<Partial<Record<string, StudentAppointmentProgress>>>({});
  const [stagedGroupNote, setStagedGroupNote] = useState("");
  const [stagedStudentNote, setStagedStudentNote] = useState("");

  const currentStudentProgress = currentStudentId ? studentProgress[currentStudentId] : undefined;

  useEffect(() => {
    if (!selectedAppointment) return;

    const defaultStudentId = selectedAppointment.students[0]?.userId;
    setCurrentStudentId(defaultStudentId);

    progressApi.getAppointmentProgress(selectedAppointment.appointmentId).then(progress => {
      const progressByStudent = keyBy(progress.students, "studentId");
      const allStudentProgress = selectedAppointment.students.reduce(
        (currentProgress, { userId: studentId }) => ({
          ...currentProgress,
          [studentId]: progressByStudent[studentId] || { studentId, goals: [], isWaived: false },
        }),
        {} as Partial<Record<string, StudentAppointmentProgress>>,
      );
      setStudentProgress(allStudentProgress);
      setStagedGroupNote(progress.groupNote || "");
      if (defaultStudentId) setStagedStudentNote(progressByStudent[defaultStudentId]?.note || "");
    });
  }, [selectedAppointment]);

  useEffect(() => {
    if (!currentStudentId) return;
    setStagedStudentNote(studentProgress[currentStudentId]?.note || "");
  }, [currentStudentId]);

  const [editingGoal, setEditingGoal] = useState<{ goal: StudentGoal; index: number }>();
  const [isEditingAllGoals, setIsEditingAllGoals] = useState(false);

  const [groupNoteAnchor, setGroupNoteAnchor] = useState<HTMLElement>();
  const [isShowingStudentNote, setIsShowingStudentNote] = useState(false);

  if (!currentUser || !["ADMIN", "PROVIDER"].includes(currentUser.userType)) return <NotFoundScreen />;

  if (!appointmentId || !selectedAppointment)
    return fetchStatus === "not-found" ? <NotFoundScreen /> : <LoadingScreen />;

  if (!currentStudentId || !currentStudentProgress) return <LoadingScreen />;

  const header = (
    <CalendarItemSubHeader
      appointment={selectedAppointment}
      navigation={{
        tabs: selectedAppointment.students.map(s => ({ key: s.userId, label: s.fullName, icon: <PersonIcon /> })),
        currentKey: currentStudentId,
        onChange: setCurrentStudentId,
      }}
      actions={
        <Stack direction="row" gap={1}>
          <ToggleButton
            value="student-note"
            selected={isShowingStudentNote}
            onChange={() => setIsShowingStudentNote(!isShowingStudentNote)}
            classes={{ selected: "Mui-selected" }}
            sx={{
              border: 0,
              borderRadius: 0,
              width: "48px",
              height: "48px",
              color: "primary.main",
              "&.Mui-selected": {
                color: "text.primary",
                backgroundColor: "primary.light",
              },
            }}
          >
            <ContactPageIcon />
          </ToggleButton>
          <ToggleButton
            value="group-note"
            selected={!!groupNoteAnchor}
            onChange={e => setGroupNoteAnchor(groupNoteAnchor ? undefined : e.currentTarget)}
            sx={{
              border: 0,
              borderRadius: 0,
              width: "48px",
              height: "48px",
              color: "primary.main",
              "&.Mui-selected": {
                color: "text.primary",
                backgroundColor: "primary.light",
              },
            }}
          >
            <NoteIcon />
          </ToggleButton>
          <Popover
            anchorEl={groupNoteAnchor}
            open={!!groupNoteAnchor}
            onClose={() => setGroupNoteAnchor(undefined)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            sx={{
              "& .MuiPopover-paper": {
                p: 2,
                bgcolor: "surface.light",
                width: 400,
              },
            }}
          >
            <TextField
              label="Group Note"
              value={stagedGroupNote}
              onChange={e => setStagedGroupNote(e.target.value)}
              onBlur={() => progressApi.setGroupNote(appointmentId, stagedGroupNote)}
              fullWidth
              multiline
              rows={12}
              inputRef={(input: HTMLInputElement | null) => input && input.focus()}
            />
          </Popover>
        </Stack>
      }
    />
  );

  const updateCurrentStudentProgress = (update: Partial<StudentAppointmentProgress>) =>
    setStudentProgress({
      ...studentProgress,
      [currentStudentId]: { ...currentStudentProgress, ...update },
    });

  const toggleWaived = () =>
    progressApi
      .updateStudentAppointmentProgress(currentStudentId, appointmentId, {
        isWaived: !currentStudentProgress.isWaived,
      })
      .then(progress => setStudentProgress({ ...studentProgress, [currentStudentId]: progress }));

  const replaceGoal = (updated: StudentGoal) =>
    setStudentProgress({
      ...studentProgress,
      [currentStudentId]: {
        ...currentStudentProgress,
        goals: currentStudentProgress.goals.map(g => (g.goalId === updated.goalId ? updated : g)),
      },
    });

  const onModalFinished = (updatedGoal?: StudentGoal) => {
    if (updatedGoal) replaceGoal(updatedGoal);
    setEditingGoal(undefined);
    setIsEditingAllGoals(false);
  };

  const onMultipleUpdateFinished = (updated?: StudentGoal[]) => {
    if (updated) {
      const mergedGoals = updated.map(updatedGoal => {
        const existingGoal = currentStudentProgress.goals.find(g => g.goalId === updatedGoal.goalId);
        if (!existingGoal) return updatedGoal;

        const mergedObjectives = updatedGoal.objectives.map(updatedObjective => {
          const existingObjective = existingGoal.objectives.find(o => o.objectiveId === updatedObjective.objectiveId);
          return { ...updatedObjective, metric: existingObjective?.metric };
        });

        return { ...updatedGoal, objectives: mergedObjectives };
      });

      setStudentProgress({
        ...studentProgress,
        [currentStudentId]: { ...currentStudentProgress, goals: mergedGoals },
      });
    }

    onModalFinished();
  };

  let modalContent: ReactNode | undefined = undefined;
  if (editingGoal) {
    modalContent = (
      <SingleGoalForm
        studentId={currentStudentId}
        goalIndex={editingGoal.index}
        editing={editingGoal.goal}
        onFinished={onModalFinished}
      />
    );
  } else if (isEditingAllGoals) {
    modalContent = (
      <MultipleGoalForm
        studentId={currentStudentId}
        existing={currentStudentProgress.goals}
        onFinished={onMultipleUpdateFinished}
      />
    );
  }

  return (
    <PrimaryLayout headerContent={header} containerStyles={{ px: 0, pb: 0 }}>
      <FullStack direction="row">
        <Box sx={{ px: 3, width: "100%" }}>
          <ProgressDisplayLayout
            studentId={currentStudentId}
            goals={currentStudentProgress.goals}
            setGoals={goals => updateCurrentStudentProgress({ goals })}
            headerAction={{ text: "Edit Goals", icon: <EditIcon />, perform: () => setIsEditingAllGoals(true) }}
            onEditGoal={setEditingGoal}
            appointmentDetails={{
              appointmentId,
              isWaived: currentStudentProgress.isWaived,
              toggleWaived,
            }}
            isReadOnly={!currentPayPeriod || selectedAppointment.startTime < currentPayPeriod.startTime}
          />
        </Box>
        {isShowingStudentNote && (
          <Box
            sx={{
              width: 400,
              height: "100%",
              p: 2,
              bgcolor: "surface.light",
              borderLeft: 1,
              borderLeftColor: "grey.200",
            }}
          >
            <TextField
              label="Student Note"
              value={stagedStudentNote}
              onChange={e => setStagedStudentNote(e.target.value)}
              onBlur={() =>
                progressApi
                  .setStudentNote(currentStudentId, appointmentId, stagedStudentNote)
                  .then(() => updateCurrentStudentProgress({ note: stagedStudentNote }))
              }
              multiline
              fullWidth
              rows={16}
            />
          </Box>
        )}
      </FullStack>
      <CenterModal isOpen={!!modalContent} onClose={onModalFinished}>
        {modalContent}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default observer(AppointmentProgressScreen);
