import { useContext, useState } from "react";
import { isEmpty, pick } from "lodash";
import { observer } from "mobx-react-lite";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import {
  BookAppointmentBody,
  ExtendedAppointment,
  bookAppointmentBodySchema,
} from "@parallel/vertex/types/calendar/appointment.types";
import AutoCompleteFetchInput from "@/components/shared/input/AutoCompleteFetchInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import RecurrenceEditModeInput from "@/components/shared/input/RecurrenceEditModeInput";
import RecurrenceInput from "@/components/shared/input/RecurrenceInput";
import TimeRangeInput from "@/components/shared/input/TimeRangeInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { UserOption } from "@/components/user/input/UserInput";
import { StoreContext } from "@/stores";

type AppointmentParams = Partial<Omit<BookAppointmentBody, "providerId" | "studentIds" | "appointmentTypeId">> & {
  provider: SelectOption | null;
  students: SelectOption[];
  appointmentType: SelectOption | null;
};

const AppointmentForm = ({
  editing,
  selectedUser,
  onClose,
}: {
  editing?: ExtendedAppointment;
  selectedUser?: UserOption;
  onClose: () => void;
}) => {
  const {
    apiStore: { calendarApi, userApi },
    calendarStore,
    authStore: { currentUser },
  } = useContext(StoreContext);

  const { currentPayPeriod } = calendarStore;

  const [params, setParams] = useState<AppointmentParams>({
    ...calendarStore.defaultTimeRange,
    ...pick(editing, "startTime", "endTime", "recurrence"),
    provider:
      selectedUser?.userType === "PROVIDER"
        ? { key: selectedUser.userId, label: selectedUser.fullName }
        : editing
          ? { key: editing.provider.userId, label: editing.provider.fullName }
          : null,
    students:
      selectedUser?.userType === "STUDENT"
        ? [{ key: selectedUser.userId, label: selectedUser.fullName }]
        : editing
          ? editing.students.map(s => ({ key: s.userId, label: s.fullName }))
          : [],
    appointmentType: editing ? { key: editing.appointmentTypeId, label: editing.appointmentType.title } : null,
  });

  const formContent = (
    <>
      <AutoCompletePageSearchInput
        label="Provider"
        search={keyword =>
          userApi.searchProviders({
            keyword,
            assignedStudentIds: isEmpty(params.students) ? undefined : params.students.map(s => s.key),
          })
        }
        getOption={p => ({ key: p.userId, label: p.fullName })}
        selected={params.provider}
        onSelect={provider => setParams({ ...params, provider })}
        disabled={currentUser?.userType !== "ADMIN"}
      />
      <TimeRangeInput
        value={pick(params, "startTime", "endTime")}
        onChange={range => setParams({ ...params, ...range })}
        minStart={currentPayPeriod?.startTime}
      />
      {(!editing || editing.recurrence) && (
        <RecurrenceInput
          recurrence={params.recurrence}
          onChange={recurrence => setParams({ ...params, recurrence })}
          eventStartTime={params.startTime || calendarStore.defaultTimeRange.startTime}
        />
      )}

      <AutoCompletePageSearchInput
        label="Students"
        search={keyword => userApi.searchStudents({ keyword, providerId: params.provider?.key })}
        getOption={s => ({ key: s.userId, label: s.fullName })}
        selected={params.students}
        onSelect={students => setParams({ ...params, students })}
        disabled={!params.provider}
      />

      <AutoCompleteFetchInput
        label="Appointment Type"
        params={
          params.provider ? { providerId: params.provider.key, studentIds: params.students.map(s => s.key) } : null
        }
        fetchOptions={fetchParams =>
          calendarApi
            .searchAppointmentTypes(fetchParams)
            .then(r => r.records.map(t => ({ key: t.appointmentTypeId, label: t.title })))
        }
        selected={params.appointmentType}
        onSelect={appointmentType => setParams({ ...params, appointmentType })}
      />
    </>
  );

  const validate = (params: AppointmentParams) => {
    if (params.startTime && currentPayPeriod && params.startTime < currentPayPeriod.startTime) return;
    return bookAppointmentBodySchema.safeParse({
      ...params,
      providerId: params.provider?.key,
      studentIds: params.students.map(s => s.key),
      appointmentTypeId: params.appointmentType?.key,
    })?.data;
  };

  const onSubmit = async (body: BookAppointmentBody) => {
    editing
      ? await calendarStore.updateAppointment(editing.appointmentId, body)
      : await calendarStore.bookAppointment(body);
    onClose();
  };

  const confirmation = editing?.recurrence
    ? {
        prompt: async () => setParams({ ...params, recurrenceEditMode: "single" }),
        content: params.recurrenceEditMode && (
          <RecurrenceEditModeInput
            params={params}
            setParams={setParams}
            actionName="update"
            recordName={{ singular: "session", plural: "sessions" }}
          />
        ),
      }
    : undefined;

  return (
    <SubmitForm
      recordName="session"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
      confirmation={confirmation}
    />
  );
};

export default observer(AppointmentForm);
