import { useContext, useState } from "react";
import { toast } from "react-toastify";
import ArchiveIcon from "@mui/icons-material/Archive";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMountEffect } from "@react-hookz/web";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import { UserArchiveReason } from "@parallel/vertex/types/user/user.types";
import SelectInput from "@/components/shared/input/SelectInput";
import FormLayout, { getCancelAction } from "@/components/shared/layout/FormLayout";
import { StoreContext } from "@/stores";

const StudentArchiveForm = ({
  student,
  onFinished,
}: {
  student: SingleStudentUser;
  onFinished: (updated: SingleStudentUser) => void;
}) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);

  const [allReasons, setAllReasons] = useState<UserArchiveReason[]>([]);
  const [selectedReasonId, setSelectedReasonId] = useState<string | null>(null);

  useMountEffect(() => userApi.getAllUserArchiveReasons().then(setAllReasons));

  const performArchive = selectedReasonId
    ? () => {
        const studentId = student.userId;
        return userApi
          .archiveStudent(studentId, selectedReasonId)
          .then(updated => {
            toast.success(`Successfully Archived ${student.fullName}`);
            onFinished(updated);
          })
          .catch(error => {
            const message = `Failed to Archive Student ${student.fullName}`;
            console.error(message, { error, studentId, selectedReasonId });
            toast.error(message);
          });
      }
    : undefined;

  const formContent = (
    <Stack gap={2} width={500}>
      <Typography variant="body1">
        If you are sure you want to archive this student, select a reason and submit below.
      </Typography>
      <Typography variant="body1">
        This student’s future individual sessions will be deleted, and the student will be removed from future group
        sessions.
      </Typography>
      <SelectInput
        label="Archive Reason"
        options={allReasons.map(r => ({ key: r.userArchiveReasonId, label: r.title }))}
        value={selectedReasonId || ""}
        onChange={setSelectedReasonId}
      />
    </Stack>
  );

  return (
    <FormLayout
      headerText={`Archive ${student.fullName}`}
      formContent={formContent}
      primaryAction={{ onClick: performArchive, label: "Archive", icon: <ArchiveIcon /> }}
      secondaryAction={getCancelAction(() => onFinished(student))}
    />
  );
};

export default StudentArchiveForm;
