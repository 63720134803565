import type { Appointment, AppointmentCompletionRequirement, ComplianceType } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { appointmentStatusEnum, recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { datetimeRequestField, paginateQueryFields, queryStringArray } from "../shared.types";
import { ExtendedUser } from "../user/user.types";
import { ExtendedCalendarItem, searchCalendarQuerySchema, timeRangeFields, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type { AppointmentType, AppointmentCancelReason, AppointmentStatus } from "@prisma/client";

export type ExtendedAppointmentUser = ExtendedUser & {
  shortCode: string;
  hasAttended: boolean | null;
  joinedAt?: DateTime | null;
  leftAt?: DateTime | null;
  campus?: { id: string; name: string };
  facilitators: ExtendedUser[];
};

export type ExtendedAppointment = ExtendedCalendarItem<Appointment> & {
  title: string;
  provider: ExtendedAppointmentUser;
  students: ExtendedAppointmentUser[];
  appointmentType: {
    title: string;
    completionRequirements: AppointmentCompletionRequirement[];
    complianceType?: ComplianceType;
    formTemplateId?: string;
  };
  recurrence?: ExtendedRecurrence;
  cancelReason?: { id: string; title: string };
};

export type AppointmentFormTemplate = {
  formTemplateId: string;
  templateTitle: string;
  featheryId: string;
  userSubmissions: {
    userId: string;
    submissions: { submissionId: string; hasAnswers: boolean }[];
  }[];
};

export type AppointmentDetails = {
  recordingFiles: { nexusUrl: string }[];
  studentMeetingConnections: {
    studentId: string;
    joinedAt: DateTime;
    leftAt?: DateTime;
  }[];
  formTemplates: AppointmentFormTemplate[];
};

export const searchAppointmentsQuerySchema = searchCalendarQuerySchema.extend({
  ...paginateQueryFields,
  studentId: z.string().uuid().optional(),
  providerId: z.string().uuid().optional(),
  slpaId: z.string().uuid().optional(),
  appointmentStatus: appointmentStatusEnum.nullable().optional(),
  recurrenceId: z.string().uuid().optional(),
});

export type SearchAppointmentsQuery = z.infer<typeof searchAppointmentsQuerySchema> & {
  complianceTypes?: ComplianceType[];
};

const appointmentBody = z.object({
  providerId: z.string().uuid(),
  ...timeRangeFields,
  studentIds: z.string().uuid().array(),
  appointmentTypeId: z.string().uuid(),
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export const bookAppointmentBodySchema = appointmentBody.refine(...timeRangeRefine);

export type BookAppointmentBody = z.infer<typeof bookAppointmentBodySchema>;

export const updateAppointmentBodySchema = appointmentBody
  .partial()
  .extend({
    appointmentStatus: appointmentStatusEnum.nullable().optional(),
    cancelReasonId: z.string().uuid().optional(),
    willMakeUpAbsence: z.boolean().optional(),
  })
  .refine(...timeRangeRefine);

export type UpdateAppointmentBody = z.infer<typeof updateAppointmentBodySchema> & { nylasEventId?: string };

export const deleteAppointmentBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteAppointmentBody = z.infer<typeof deleteAppointmentBodySchema>;

export const updateAppointmentUserBodySchema = z.object({
  hasAttended: z.boolean().optional(),
  joinedAt: datetimeRequestField.nullable().optional(),
  leftAt: datetimeRequestField.nullable().optional(),
});

export type UpdateAppointmentUserBody = z.infer<typeof updateAppointmentUserBodySchema>;

export const searchAppointmentTypeQuerySchema = z.object({
  providerId: z.string().uuid().optional(),
  studentIds: queryStringArray(z.string().uuid()).optional(),
  ...paginateQueryFields,
});

export type SearchAppointmentTypeQuery = z.infer<typeof searchAppointmentTypeQuerySchema>;
