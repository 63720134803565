import { useContext, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { AppointmentStatus } from "@parallel/vertex/types/calendar/appointment.types";
import { StoreContext } from "@/stores";

export const AppointmentAbsenceMakeUpInput = ({
  willMakeUpAbsence,
  setWillMakeUpAbsence,
}: {
  willMakeUpAbsence: boolean;
  setWillMakeUpAbsence: (b: boolean) => void;
}) => (
  <Stack gap={1} width={400}>
    <Typography variant="body1">Session make-up needed?</Typography>
    <FormControl>
      <RadioGroup
        value={willMakeUpAbsence ? "yes" : "no"}
        onChange={e => setWillMakeUpAbsence(e.target.value === "yes")}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  </Stack>
);

const AppointmentAbsenceMakeUpPrompt = ({
  appointmentId,
  pendingStatus,
  onBack,
}: {
  appointmentId: string;
  pendingStatus: AppointmentStatus;
  onBack: () => void;
}) => {
  const { calendarStore } = useContext(StoreContext);

  const [willMakeUpAbsence, setWillMakeUpAbsence] = useState(true);

  const submit = async () => {
    await calendarStore.updateAppointment(appointmentId, { appointmentStatus: pendingStatus, willMakeUpAbsence });
    onBack();
  };

  return (
    <Stack gap={2} width={400}>
      <AppointmentAbsenceMakeUpInput
        willMakeUpAbsence={willMakeUpAbsence}
        setWillMakeUpAbsence={setWillMakeUpAbsence}
      />
      <Stack direction="row" gap={1}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onBack} fullWidth>
          Back
        </Button>
        <ProcessButton process={submit} variant="contained" startIcon={<SaveIcon />} fullWidth>
          Save
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default AppointmentAbsenceMakeUpPrompt;
