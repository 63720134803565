import { useContext, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { SingleStudentUser, UpdateStudentBody } from "@parallel/vertex/types/user/student.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import StudentInfoInput from "@/components/user/student/form/StudentInfoInput";
import StudentServiceInput from "@/components/user/student/form/StudentServiceInput";
import { StoreContext } from "@/stores";
import { getInitialStudentParams, parseInfoBody, parseServicesBody } from "@/util/student.form.util";

export type StudentEditColumn = "info" | "services" | "providers" | "facilitators";

const StudentEditForm = ({
  column,
  student,
  onFinished,
}: {
  column: StudentEditColumn;
  student: SingleStudentUser;
  onFinished: (maybeUpdated: SingleStudentUser) => void;
}) => {
  const {
    apiStore: { serviceApi, userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState(getInitialStudentParams(student));

  const [allRequirementTypes, setAllRequirementTypes] = useState<ServiceRequirementType[]>([]);
  useMountEffect(() => {
    if (column !== "services") return;
    serviceApi.searchServiceRequirementTypes().then(setAllRequirementTypes);
  });

  const commonFormProps = {
    operationName: "update" as const,
    params,
    onSubmit: (b: UpdateStudentBody) => userApi.updateStudent(student.userId, b).then(onFinished),
    onCancel: () => onFinished(student),
  };

  switch (column) {
    case "info":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student info"
          formContent={<StudentInfoInput params={params} setParams={setParams} />}
          validate={parseInfoBody}
        />
      );
    case "services":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student services"
          formContent={
            <StudentServiceInput
              params={params}
              setParams={setParams}
              allRequirementTypes={allRequirementTypes}
              currentServiceCount={student.services.length}
            />
          }
          validate={params => parseServicesBody(params, allRequirementTypes)}
        />
      );
    case "facilitators":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student facilitators"
          formContent={
            <AutoCompletePageSearchInput
              label="Facilitators"
              search={keyword => userApi.searchFacilitators({ keyword })}
              getOption={u => ({ key: u.userId, label: u.fullName })}
              selected={params.facilitators}
              onSelect={facilitators => setParams({ ...params, facilitators })}
            />
          }
          validate={parseInfoBody}
        />
      );
    case "providers":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student providers"
          formContent={
            <AutoCompletePageSearchInput
              label="Providers"
              search={keyword => userApi.searchProviders({ keyword })}
              getOption={u => ({ key: u.userId, label: u.fullName })}
              selected={params.providers}
              onSelect={providers => setParams({ ...params, providers })}
            />
          }
          validate={parseInfoBody}
        />
      );
  }
};

export default StudentEditForm;
