import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useMountEffect } from "@react-hookz/web";
import { get } from "lodash";
import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { AVAILABLE_LANGUAGES, LANGUAGE_NAMES } from "@parallel/vertex/enums/user.enums";
import { ServiceLine, ServiceRequirementType } from "@parallel/vertex/types/service.types";
import StudentServiceRequirementInput from "@/components/user/student/form/StudentServiceRequirementInput";
import { StoreContext } from "@/stores";
import {
  StudentParams,
  StudentServiceParams,
  buildDefaultCadence,
  buildDefaultService,
} from "@/util/student.form.util";

const StudentServiceInput = ({
  params,
  setParams,
  allRequirementTypes,
  currentServiceCount = 0,
}: {
  params: StudentParams;
  setParams: (b: StudentParams) => void;
  allRequirementTypes: ServiceRequirementType[];
  currentServiceCount?: number;
}) => {
  const {
    apiStore: { serviceApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [allServiceLines, setAllServiceLines] = useState<ServiceLine[]>([]);
  useMountEffect(() => serviceApi.searchServiceLines().then(setAllServiceLines));

  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const currentServiceParams = params.services[currentServiceIndex];
  const currentServiceLine = allServiceLines.find(l => l.serviceLineId === currentServiceParams.serviceLineId);

  const currentRequirementTypes = allRequirementTypes.filter(
    t => currentServiceLine && t.productCodes.includes(currentServiceLine.productCode),
  );

  const updateCurrentService = (update: Partial<StudentServiceParams>, { override }: { override?: boolean } = {}) => {
    setParams({
      ...params,
      services: params.services.map((s, i) =>
        i === currentServiceIndex ? { ...(override ? {} : currentServiceParams), ...update } : s,
      ),
    });
  };

  const setServiceLine = (serviceLineId?: string) => {
    const newServiceLine = allServiceLines.find(l => l.serviceLineId === serviceLineId);

    const requirementTypes = allRequirementTypes.filter(
      t => newServiceLine && t.productCodes.includes(newServiceLine.productCode),
    );

    const defaultProvider =
      currentUser?.userType === "PROVIDER" ? { key: currentUser.userId, label: currentUser.fullName } : null;

    const update = requirementTypes.reduce(
      (currUpdate, nextType) => {
        const typeId = nextType.serviceRequirementTypeId;
        switch (nextType.dataType) {
          case "CADENCE":
            return {
              ...currUpdate,
              cadences: {
                ...currUpdate.cadences,
                [typeId]: get(currentServiceParams, `cadences.${typeId}`, buildDefaultCadence()),
              },
            };
          case "DATE":
          case "END_DATE":
          case "START_DATE":
            return {
              ...currUpdate,
              dates: { ...currUpdate.dates, [typeId]: get(currentServiceParams, `dates.${typeId}`, null) },
            };
          case "PROVIDER":
            return {
              ...currUpdate,
              users: {
                ...currUpdate.users,
                [typeId]: get(currentServiceParams, `users.${typeId}`, defaultProvider),
              },
            };
          case "SLPA":
            return {
              ...currUpdate,
              users: {
                ...currUpdate.users,
                [typeId]: get(currentServiceParams, `users.${typeId}`, null),
              },
            };
        }
      },
      { ...buildDefaultService(), serviceLineId, productCode: newServiceLine?.productCode } as StudentServiceParams,
    );
    updateCurrentService(update, { override: true });
  };

  const findDateParam = (type: "START_DATE" | "END_DATE") => {
    const requirementId = currentRequirementTypes.find(t => t.dataType === type)?.serviceRequirementTypeId;
    return requirementId ? get(currentServiceParams.dates, requirementId, null) : null;
  };
  const startTime = findDateParam("START_DATE");
  const endTime = findDateParam("END_DATE");

  return (
    <Stack width={1000} height={500}>
      <Grid container height="100%">
        <Grid xs={4} borderRight={1} borderColor="grey.400" mt={1} pr={2}>
          {params.services.map((s, i) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setCurrentServiceIndex(i)}
              key={i}
              sx={{
                px: 2,
                py: 1,
                minHeight: 56,
                borderRadius: 1,
                bgcolor: i === currentServiceIndex ? "primary.main" : undefined,
                color: i === currentServiceIndex ? "white" : undefined,
                cursor: "pointer",
              }}
            >
              <Typography variant="body1" fontStyle={!s.serviceLineId ? "italic" : undefined}>
                {allServiceLines.find(l => l.serviceLineId === s.serviceLineId)?.title || "[Missing Service Line]"}
              </Typography>
              {params.services.length > 1 && i >= currentServiceCount && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    if (i < currentServiceIndex || (i === currentServiceIndex && i === params.services.length - 1))
                      setCurrentServiceIndex(currentServiceIndex - 1);
                    setParams({ ...params, services: params.services.filter((_, j) => j !== i) });
                  }}
                >
                  <DeleteIcon sx={{ color: i === currentServiceIndex ? "white" : undefined }} />
                </IconButton>
              )}
            </Stack>
          ))}
          <Button
            fullWidth
            onClick={() => setParams({ ...params, services: [...params.services, buildDefaultService()] })}
            startIcon={<AddIcon />}
            sx={{ mt: 1 }}
          >
            Add Service
          </Button>
        </Grid>

        <Grid xs={8} pl={2} pt={1} pr={1} height="100%" overflow="auto">
          <FullStack gap={2}>
            <Stack gap={2}>
              <AutoCompleteInput
                label="Service Line"
                options={allServiceLines.map(l => ({ key: l.serviceLineId, label: l.title }))}
                selected={
                  currentServiceLine ? { key: currentServiceLine.serviceLineId, label: currentServiceLine.title } : null
                }
                onSelect={selected => setServiceLine(selected?.key)}
                disabled={currentServiceIndex < currentServiceCount}
              />
              <AutoCompleteInput
                label="Service Language"
                options={AVAILABLE_LANGUAGES.map(key => ({ key, label: LANGUAGE_NAMES[key] }))}
                selected={currentServiceParams.languages?.map(key => ({ key, label: LANGUAGE_NAMES[key] })) || []}
                onSelect={selected => updateCurrentService({ languages: selected.map(s => s.key) })}
              />
            </Stack>

            {currentRequirementTypes.map(requirementType => (
              <StudentServiceRequirementInput
                serviceLine={currentServiceLine}
                requirementType={requirementType}
                serviceParams={currentServiceParams}
                updateServiceParams={updateCurrentService}
                allParams={params}
                allRequirementTypes={allRequirementTypes}
                timeRange={startTime && endTime ? { startTime, endTime } : undefined}
                key={requirementType.serviceRequirementTypeId}
              />
            ))}
          </FullStack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StudentServiceInput;
