import { ReactElement, ReactNode, cloneElement } from "react";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const SUB_HEADER_HEIGHT = "48px";

type HeaderBadge = { label: string; icon?: ReactElement };

export type HeaderNavigation<A extends string> = {
  tabs: (HeaderBadge & { key: A })[];
  currentKey?: A;
  onChange: (key: A) => void;
};

const SubHeader = <A extends string>({
  icon,
  title,
  subtitle,
  badges,
  navigation,
  actions,
}: {
  icon: ReactElement;
  title: string;
  subtitle?: string;
  badges?: HeaderBadge[];
  navigation?: HeaderNavigation<A>;
  actions?: ReactNode;
}) => {
  const tooltipContents = badges ? (
    <Stack sx={{ padding: 0.5 }}>
      {badges.map(({ label, icon }, i) => (
        <Stack direction="row" gap={1} alignItems="center" key={i}>
          {icon && cloneElement(icon, { sx: { height: 16, width: 16 } })}
          <Typography variant="body2">{label}</Typography>
        </Stack>
      ))}
    </Stack>
  ) : undefined;

  return (
    <Stack
      direction="row"
      width="100%"
      height={SUB_HEADER_HEIGHT}
      px={3}
      justifyContent="space-between"
      alignItems="center"
      bgcolor="surface.light"
      sx={{ borderBottom: 1, borderBottomColor: "grey.300" }}
    >
      <Stack direction="row" gap={3} alignItems="center">
        <Tooltip title={tooltipContents} placement="bottom-start">
          <Stack direction="row" gap={1.5} alignItems="center" sx={{ cursor: "pointer" }}>
            {cloneElement(icon, { sx: { fontSize: "20px", color: "text.secondary" } })}
            <Typography variant="body1">{title}</Typography>
            {subtitle && (
              <Typography sx={{ color: "text.secondary" }} variant="body1">
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Tooltip>

        {navigation && (
          <Tabs
            value={navigation.currentKey}
            onChange={(_, key) => navigation.onChange(key)}
            sx={{ borderLeft: 1, borderLeftColor: "grey.300" }}
          >
            {navigation.tabs.map(tab => (
              <Tab
                value={tab.key}
                label={<Typography variant="body1">{tab.label}</Typography>}
                icon={tab.icon ? cloneElement(tab.icon, { style: { fontSize: "20px" } }) : undefined}
                iconPosition="start"
                sx={{
                  minHeight: SUB_HEADER_HEIGHT,
                  height: SUB_HEADER_HEIGHT,
                  paddingX: 3,
                  borderRight: 1,
                  borderRightColor: "grey.300",
                  color: "primary.main",
                  "&.Mui-selected": {
                    color: "text.secondary",
                    bgcolor: "white",
                    borderBottom: 1,
                  },
                }}
                key={tab.key}
              />
            ))}
          </Tabs>
        )}
      </Stack>

      {actions}
    </Stack>
  );
};

export default SubHeader;
