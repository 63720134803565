import { AuthorizedUser, ExtendedPasswordReset } from "@parallel/vertex/types/auth.types";
import { SignInError } from "@parallel/vertex/util/nexus.base.api";
import { BaseAPI } from "@/api/base.api";

export class AuthAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public passwordSignIn = (request: { email: string; password: string }): Promise<AuthorizedUser> =>
    this.instance
      .post("auth/password", request)
      .then(r => r.data)
      .catch(e => {
        // TODO configure throttling
        throw new SignInError(e);
      });

  public googleSignIn = (token: string): Promise<AuthorizedUser> =>
    this.instance.post("auth/google", { token }).then(r => r.data);

  public cleverSignIn = (token: string): Promise<AuthorizedUser> =>
    this.instance.post("auth/clever", { token }).then(r => r.data);

  public restoreSignIn = (): Promise<AuthorizedUser> => this.instance.post("auth/restore").then(r => r.data);

  public signOut = (): Promise<void> => this.instance.delete("auth");

  public startPasswordReset = (email: string): Promise<void> => this.instance.post("/auth/password-reset", { email });

  public getPasswordReset = (resetId: string): Promise<ExtendedPasswordReset> =>
    this.instance.get(`/auth/password-reset/${resetId}`).then(r => r.data);

  public performPasswordReset = (resetId: string, password: string): Promise<void> =>
    this.instance.post(`/auth/password-reset/${resetId}/perform`, { password });
}
