import { useContext } from "react";
import Box from "@mui/material/Box";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import { StoreContext } from "@/stores";

export type UserOption = Pick<ExtendedUser, "userId" | "fullName" | "userType">;

const toOption = (u: UserOption) => ({ key: u.userId, label: u.fullName, groupName: u.userType });

const UserInput = ({
  label,
  selected,
  onSelect,
  userTypes,
  size,
}: {
  label?: string;
  selected: UserOption | null;
  onSelect: (s: UserOption | null) => void;
  userTypes: UserType[];
  size?: "small";
}) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);
  return (
    <Box width={300}>
      <AutoCompletePageSearchInput
        label={label || "User"}
        search={keyword => userApi.searchUsers({ keyword, userTypes })}
        getOption={u => ({ key: u.userId, label: u.fullName, groupName: u.userType })}
        selected={selected ? toOption(selected) : null}
        onSelect={o => onSelect(o ? { userId: o.key, fullName: o.label, userType: o.groupName as UserType } : null)}
        size={size}
      />
    </Box>
  );
};

export default UserInput;
