import { useContext, useState } from "react";
import { toast } from "react-toastify";
import ArchiveIcon from "@mui/icons-material/Archive";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMountEffect } from "@react-hookz/web";
import { ServiceLineClientArchiveReason } from "@parallel/vertex/types/service.types";
import { StudentService } from "@parallel/vertex/types/user/student.types";
import SelectInput from "@/components/shared/input/SelectInput";
import FormLayout, { getCancelAction } from "@/components/shared/layout/FormLayout";
import { StoreContext } from "@/stores";

const StudentServiceArchiveForm = ({
  studentName,
  service,
  onArchived,
  onClose,
}: {
  studentName: string;
  service: StudentService;
  onArchived: () => void;
  onClose: () => void;
}) => {
  const {
    apiStore: { serviceApi },
  } = useContext(StoreContext);

  const [reasons, setReasons] = useState<ServiceLineClientArchiveReason[]>([]);
  const [selectedReasonId, setSelectedReasonId] = useState<string | null>(null);

  useMountEffect(() => serviceApi.getAllServiceClientArchiveReasons().then(setReasons));

  const formContent = (
    <Stack gap={2} width={500}>
      <Typography variant="body1">
        If you are sure you want to deactivate the {service.title} service line for student {studentName}, select a
        reason and submit below.
      </Typography>
      <SelectInput
        label="Archive Reason"
        options={reasons.map(r => ({ key: r.serviceLineClientArchiveReasonId, label: r.title }))}
        value={selectedReasonId || ""}
        onChange={setSelectedReasonId}
      />
    </Stack>
  );

  const performArchive = selectedReasonId
    ? () => {
        const { serviceClientId } = service;
        return serviceApi
          .archiveStudentService(serviceClientId, selectedReasonId)
          .then(() => {
            toast.success(`Successfully Archived ${service.title} Service`);
            onArchived();
          })
          .catch(error => {
            const message = `Failed to Archive ${service.title} Service`;
            console.error(message, { error, serviceClientId, selectedReasonId });
            toast.error(message);
          });
      }
    : undefined;

  return (
    <FormLayout
      headerText={`Archive Student Service`}
      formContent={formContent}
      primaryAction={{ onClick: performArchive, label: "Archive", icon: <ArchiveIcon /> }}
      secondaryAction={getCancelAction(onClose)}
    />
  );
};

export default StudentServiceArchiveForm;
