import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isArray, isEmpty, noop } from "lodash";

export type SelectOption = { key: string; label: string; groupName?: string };

export type BaseAutoCompleteProps<S extends SelectOption | null | SelectOption[]> = {
  label: string;
  selected: S;
  onSelect: (selected: S) => void;
  disabled?: boolean;
  size?: "small";
  error?: string;
};

const TOTAL_LABEL_OPTION_KEY = "TOTAL_LABEL";

const AutoCompleteInput = <S extends SelectOption | null | SelectOption[]>({
  label,
  options = [],
  totalMatchCount,
  selected,
  onSelect,
  onInput = noop,
  loadingText,
  disabled,
  size,
  width,
  error,
}: BaseAutoCompleteProps<S> & {
  options: SelectOption[] | undefined;
  totalMatchCount?: number;
  onInput?: (input: string) => void;
  isLoading?: boolean;
  loadingText?: string;
  width?: number;
}) => {
  const [inputValue, setInputValue] = useState("");
  const isGrouped = options && !options.some(o => !o.groupName);

  const finalOptions =
    !isEmpty(options) && totalMatchCount && totalMatchCount > options.length
      ? [
          { key: TOTAL_LABEL_OPTION_KEY, label: `Showing ${options.length} out of ${totalMatchCount} matches` },
          ...options,
        ]
      : options;

  return (
    <Autocomplete
      fullWidth={!width}
      multiple={isArray(selected)}
      loading={!!loadingText}
      loadingText={loadingText}
      options={finalOptions}
      getOptionLabel={option => option.label}
      filterOptions={(options, state) =>
        options.filter(
          o => o.key === TOTAL_LABEL_OPTION_KEY || o.label.toLowerCase().includes(state.inputValue.toLowerCase()),
        )
      }
      groupBy={isGrouped ? option => option.groupName || "" : undefined}
      renderInput={params => <TextField {...params} label={label} helperText={error} error={!!error} />}
      value={selected}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={(_, selected: any) => {
        onSelect(selected);
        setInputValue("");
        onInput("");
      }}
      inputValue={inputValue}
      onInputChange={(_, newValue) => {
        setInputValue(newValue);
        onInput(newValue);
      }}
      onFocus={() => onInput("")}
      disabled={disabled}
      size={size}
      sx={{ width }}
      renderOption={(props, option) => {
        if (option.key === TOTAL_LABEL_OPTION_KEY) {
          return (
            <li>
              <Typography variant="body2" fontStyle="italic" pb={1.5} pl={1}>
                {option.label}
              </Typography>
            </li>
          );
        }
        return <li {...props}>{option.label}</li>;
      }}
    />
  );
};

export default AutoCompleteInput;
