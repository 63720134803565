import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { useMountEffect } from "@react-hookz/web";
import {
  ObjectiveType,
  SetStudentGoalsBody,
  StudentGoal,
  setStudentGoalsBodySchema,
} from "@parallel/vertex/types/progress.types";
import MultipleGoalInput from "@/components/progress/form/MultipleGoalInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { StoreContext } from "@/stores";
import { MultipleGoalParams, getInitialGoalParams, transformGoalObjectiveValues } from "@/util/progress.form.util";

const MultipleGoalForm = ({
  studentId,
  existing,
  onFinished,
}: {
  studentId: string;
  existing: StudentGoal[];
  onFinished: (updated?: StudentGoal[]) => void;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState<MultipleGoalParams>({
    goals: existing.map(g => ({ goalId: g.goalId, ...getInitialGoalParams(g) })),
  });

  const [allObjectiveTypes, setAllObjectiveTypes] = useState<ObjectiveType[]>([]);
  useMountEffect(() => progressApi.getAllObjectiveTypes().then(setAllObjectiveTypes));

  const formContent = (
    <Box width={1000} height={500}>
      <MultipleGoalInput params={params} setParams={setParams} allObjectiveTypes={allObjectiveTypes} />
    </Box>
  );

  const validate = (params: MultipleGoalParams) =>
    setStudentGoalsBodySchema.safeParse({
      goals: params.goals.map(g => transformGoalObjectiveValues(g, allObjectiveTypes)),
    })?.data;

  const onSubmit = async (request: SetStudentGoalsBody) => {
    const updated = await progressApi.setAllStudentGoals(studentId, request);
    onFinished(updated);
  };

  return (
    <SubmitForm
      recordName="goals"
      operationName="update"
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onFinished}
    />
  );
};

export default MultipleGoalForm;
