import { get, groupBy, isEmpty, isNull, keyBy, mapValues, pick, pickBy } from "lodash";
import { DateTime } from "luxon";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { LanguageType } from "@parallel/vertex/enums/user.enums";
import { ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { Override } from "@parallel/vertex/types/shared.types";
import {
  CreateStudentInfoBody,
  CreateStudentServicesBody,
  SingleStudentUser,
  StudentServiceCadenceRequest,
  StudentServiceRequest,
  createStudentInfoBodySchema,
  createStudentServicesBodySchema,
} from "@parallel/vertex/types/user/student.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { SingleGoalParams } from "@/util/progress.form.util";
import { isAssessmentServiceLine } from "@/util/student.util";

export type StudentInfoParams = Override<
  Partial<Omit<CreateStudentInfoBody, "campusId" | "providerIds" | "facilitatorIds">>,
  {
    campus: SelectOption | null;
    providers: SelectOption[];
    facilitators: SelectOption[];
  }
>;

export type StudentServiceParams = Override<
  Partial<StudentServiceRequest>,
  {
    cadences?: Record<string, Partial<StudentServiceCadenceRequest> | null | undefined>;
    dates?: Record<string, DateTime | null | undefined>;
    languages?: LanguageType[];
    productCode?: string;
    reportTypes?: string[];
    users?: Record<string, SelectOption | null>;
  }
>;

export type StudentParams = StudentInfoParams & {
  services: StudentServiceParams[];
  goals: SingleGoalParams[];
};

export const DEFAULT_CADENCE_UNIT = "WEEKLY";
export const DEFAULT_CADENCE_SIZE = 1;

export const buildDefaultCadence = (): Partial<StudentServiceCadenceRequest> => ({
  unit: DEFAULT_CADENCE_UNIT,
  size: DEFAULT_CADENCE_SIZE,
});

export const buildDefaultService = (): StudentServiceParams => ({
  languages: ["en_US"],
});

export const getInitialStudentParams = (student?: SingleStudentUser): StudentParams => ({
  ...pick(student, "firstName", "lastName", "birthDate", "email"),
  languages: student?.languages || ["en_US"],
  services:
    !student || isEmpty(student.services)
      ? [buildDefaultService()]
      : student.services.map(service => ({
          ...pick(service, "serviceLineId", "languages"),
          cadences: mapValues(keyBy(service.requirements.cadences, "requirementTypeId"), cadence =>
            cadence.repeat
              ? {
                  minutes: cadence.minutes,
                  size: cadence.repeat.size,
                  unit: cadence.repeat.unit,
                }
              : {
                  totalMinutesOverride: cadence.minutes,
                },
          ),
          dates: mapValues(keyBy(service.requirements.dates, "requirementTypeId"), r =>
            student.archivedAt && r.type === "END_DATE" ? null : r.value,
          ),
          productCode: service.productCode,
          reportTypes: service.reportTypes,
          users: mapValues(keyBy(service.requirements.users, "requirementTypeId"), r => ({
            key: r.userId,
            label: r.fullName,
          })),
        })),
  campus: student?.campus ? { key: student.campus.id, label: student.campus.name } : null,
  providers: student ? student.providers.map(p => ({ key: p.userId, label: p.fullName })) : [],
  facilitators: student ? student.facilitators.map(f => ({ key: f.userId, label: f.fullName })) : [],
  goals: [],
});

export const parseInfoBody = (params: StudentParams): CreateStudentInfoBody | undefined =>
  createStudentInfoBodySchema.safeParse({
    ...params,
    campusId: params.campus?.key,
    providerIds: params.providers.map(p => p.key),
    facilitatorIds: params.facilitators.map(f => f.key),
  })?.data;

const filterIncompleteServiceRequirements = <A>(
  requirements: Record<string, A | null> | undefined,
  allRequirementTypes: ServiceRequirementType[],
  shouldOmit: (a: A) => boolean = () => false,
): Record<string, A | null> =>
  pickBy(requirements, (value, typeId) => {
    const requirementType = allRequirementTypes.find(t => t.serviceRequirementTypeId === typeId);
    if (requirementType?.isRequired) return true; // if required, always leave + validate
    if (isNull(value)) return false; // if not required + null, omit from validation / request body
    return !shouldOmit(value); // if not required + not null, use param (leave by default)
  });

export const getDuplicateAssessmentProviderIds = (
  params: StudentParams,
  allRequirementTypes: ServiceRequirementType[],
): string[] => {
  const providerTypeId = allRequirementTypes.find(t => t.dataType === "PROVIDER")?.serviceRequirementTypeId;
  if (!providerTypeId) return [];

  const allAssessmentProviderOptions = mapExists(params.services, s => {
    if (!s.productCode || !isAssessmentServiceLine(s.productCode)) return;
    return get(s.users, providerTypeId, null);
  });
  const groupedProviderOptions = groupBy(allAssessmentProviderOptions, "key");
  return Object.keys(pickBy(groupedProviderOptions, options => options.length > 1));
};

export const parseServicesBody = (
  params: StudentParams,
  allRequirementTypes: ServiceRequirementType[],
): CreateStudentServicesBody | undefined => {
  const services = params.services.map(service => {
    const cadenceParams = mapValues(service.cadences, r => ({ ...buildDefaultCadence(), ...r }));
    const isCadenceValid = (c: Partial<StudentServiceCadenceRequest>) => !!c?.minutes || !!c?.totalMinutesOverride;

    // fail validation if service line includes more than one cadence requirement and they are all empty in the params
    const allCadenceParams = Object.values(cadenceParams);
    if (allCadenceParams.length > 1 && !isEmpty(allCadenceParams) && !allCadenceParams.some(isCadenceValid))
      return null;

    const invalidProviderIds = getDuplicateAssessmentProviderIds(params, allRequirementTypes);
    if (!isEmpty(invalidProviderIds)) return null;

    return {
      ...service,
      cadences: filterIncompleteServiceRequirements(cadenceParams, allRequirementTypes, c => !isCadenceValid(c)),
      dates: filterIncompleteServiceRequirements(service.dates, allRequirementTypes),
      users: mapValues(filterIncompleteServiceRequirements(service.users, allRequirementTypes), option => option?.key),
    };
  });

  return createStudentServicesBodySchema.safeParse({ services })?.data;
};
