import { ReactNode, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useMountEffect } from "@react-hookz/web";
import { isNull } from "lodash";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { SingleStudentUser, StudentService } from "@parallel/vertex/types/user/student.types";
import { updateAtIndex } from "@parallel/vertex/util/collection.util";
import SingleGoalForm from "@/components/progress/form/SingleGoalForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import StudentHeader from "@/components/user/student/StudentHeader";
import StudentInfo from "@/components/user/student/StudentInfo";
import StudentProgressConfiguration from "@/components/user/student/StudentProgressConfiguration";
import StudentProgressReport from "@/components/user/student/StudentProgressReport";
import StudentArchiveForm from "@/components/user/student/form/StudentArchiveForm";
import StudentEditForm, { StudentEditColumn } from "@/components/user/student/form/StudentEditForm";
import StudentRestoreForm from "@/components/user/student/form/StudentRestoreForm";
import StudentServiceArchiveForm from "@/components/user/student/form/StudentServiceArchiveForm";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";
import { StudentViewType } from "@/util/student.util";

const StudentScreen = ({ currentView = "info" }: { currentView?: StudentViewType }) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);

  const { studentId } = useParams();
  const [student, setStudent] = useState<SingleStudentUser | null>();

  useMountEffect(() => {
    if (!studentId) {
      setStudent(null);
      return;
    }
    userApi
      .getStudent(studentId)
      .then(setStudent)
      .catch(e => {
        console.error("error fetching student", e);
        setStudent(null);
      });
  });

  const [editColumn, setEditColumn] = useState<StudentEditColumn>();

  const [isArchiving, setIsArchiving] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [editingGoal, setEditingGoal] = useState<{ goal: StudentGoal; index: number }>();

  const [archivingStudentService, setArchivingStudentService] = useState<StudentService>();

  if (isNull(student)) return <NotFoundScreen />;
  if (!student)
    return (
      <PrimaryLayout>
        <LoadingScreen />
      </PrimaryLayout>
    );

  const onModalFinished = (updated: SingleStudentUser) => {
    setStudent(updated);
    setEditColumn(undefined);
    setIsArchiving(false);
    setIsRestoring(false);
    setIsAddingGoal(false);
    setEditingGoal(undefined);
    setArchivingStudentService(undefined);
  };

  const onGoalWrite = (written?: StudentGoal) => {
    if (!written) {
      onModalFinished(student);
      return;
    }
    const updateIndex = student.goals.findIndex(g => g.goalId === written.goalId);
    const goalsUpdate =
      updateIndex === -1 ? [...student.goals, written] : updateAtIndex(student.goals, updateIndex, written);
    onModalFinished({ ...student, goals: goalsUpdate });
  };

  let modalContent: ReactNode | undefined = undefined;
  if (editColumn) {
    modalContent = <StudentEditForm student={student} column={editColumn} onFinished={onModalFinished} />;
  } else if (isArchiving) {
    modalContent = <StudentArchiveForm student={student} onFinished={onModalFinished} />;
  } else if (isRestoring) {
    modalContent = <StudentRestoreForm student={student} onFinished={onModalFinished} />;
  } else if (editingGoal || isAddingGoal) {
    modalContent = (
      <SingleGoalForm
        studentId={student.userId}
        goalIndex={editingGoal ? editingGoal.index : student.goals.length + 1}
        editing={editingGoal?.goal}
        onFinished={onGoalWrite}
      />
    );
  } else if (archivingStudentService) {
    modalContent = (
      <StudentServiceArchiveForm
        studentName={student.fullName}
        service={archivingStudentService}
        onArchived={() =>
          onModalFinished({
            ...student,
            services: student.services.filter(s => s.serviceClientId !== archivingStudentService.serviceClientId),
          })
        }
        onClose={() => onModalFinished(student)}
      />
    );
  }

  return (
    <PrimaryLayout
      headerContent={
        <StudentHeader
          student={student}
          currentView={currentView}
          onArchive={() => setIsArchiving(true)}
          onRestore={() => setIsRestoring(true)}
        />
      }
    >
      {currentView === "info" && (
        <StudentInfo student={student} onEdit={setEditColumn} onServiceArchive={setArchivingStudentService} />
      )}
      {currentView === "goals" && (
        <StudentProgressConfiguration
          student={student}
          onStudentUpdated={setStudent}
          onGoalAdd={() => setIsAddingGoal(true)}
          onGoalEdit={setEditingGoal}
        />
      )}
      {currentView === "progress" && <StudentProgressReport studentId={student.userId} />}

      <CenterModal isOpen={!!modalContent} onClose={() => onModalFinished(student)}>
        {modalContent}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default StudentScreen;
