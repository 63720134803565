import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { useMountEffect } from "@react-hookz/web";
import {
  CreateGoalBody,
  ObjectiveType,
  StudentGoal,
  createGoalBodySchema,
} from "@parallel/vertex/types/progress.types";
import SingleGoalInput from "@/components/progress/form/SingleGoalInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { StoreContext } from "@/stores";
import { SingleGoalParams, getInitialGoalParams, transformGoalObjectiveValues } from "@/util/progress.form.util";

const SingleGoalForm = ({
  studentId,
  goalIndex,
  editing,
  onFinished,
}: {
  studentId: string;
  goalIndex: number;
  editing?: StudentGoal;
  onFinished: (maybeUpdated?: StudentGoal) => void;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState<SingleGoalParams>(getInitialGoalParams(editing));

  const [allObjectiveTypes, setAllObjectiveTypes] = useState<ObjectiveType[]>([]);
  useMountEffect(() => progressApi.getAllObjectiveTypes().then(setAllObjectiveTypes));

  const formContent = (
    <Box width={1000} height={500}>
      <SingleGoalInput
        goal={params}
        goalIndex={goalIndex}
        updateGoal={update => setParams({ ...params, ...update })}
        allObjectiveTypes={allObjectiveTypes}
        isEditing={!!editing}
      />
    </Box>
  );

  const validate = (params: SingleGoalParams) =>
    createGoalBodySchema.safeParse(transformGoalObjectiveValues(params, allObjectiveTypes))?.data;

  const onSubmit = async (request: CreateGoalBody) => {
    const updated = editing
      ? await progressApi.updateStudentGoal(studentId, editing.goalId, request)
      : await progressApi.createStudentGoal(studentId, request);

    onFinished(updated);
  };

  return (
    <SubmitForm
      recordName="goal"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onFinished}
    />
  );
};

export default SingleGoalForm;
