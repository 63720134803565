import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListIcon from "@mui/icons-material/List";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useMountEffect } from "@react-hookz/web";
import { isEmpty, isEqual, uniq } from "lodash";
import { ProcessButton, SelectWithStatus } from "@parallel/polygon/components/shared/input/status.input";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { AppointmentStatus, CANCEL_STATUSES, CancelStatus } from "@parallel/vertex/enums/calendar.enums";
import { ExtendedAppointment, AppointmentDetails } from "@parallel/vertex/types/calendar/appointment.types";
import { FormType } from "@parallel/vertex/types/form.types";
import { AppointmentProgress } from "@parallel/vertex/types/progress.types";
import { getTelehealthJoinDetails } from "@parallel/vertex/util/appointment.util";
import AppointmentAbsenceMakeUpPrompt from "@/components/calendar/appointment/AppointmentAbsenceMakeUpPrompt";
import AppointmentForm from "@/components/calendar/appointment/AppointmentForm";
import AppointmentObservationFormPrompt from "@/components/calendar/appointment/AppointmentObservationFormPrompt";
import AppointmentProgressMetricPrompt from "@/components/calendar/appointment/AppointmentProgressMetricPrompt";
import AppointmentProgressNotePrompt from "@/components/calendar/appointment/AppointmentProgressNotePrompt";
import AppointmentRecordingSelect from "@/components/calendar/appointment/AppointmentRecordingSelect";
import CancelAppointmentForm from "@/components/calendar/appointment/CancelAppointmentForm";
import StudentAttendanceDisplay from "@/components/calendar/appointment/StudentAttendanceDisplay";
import CommonDisplay from "@/components/shared/layout/CommonDisplay";
import config from "@/config";
import { StoreContext } from "@/stores";
import { doesProgressMeetRequirement } from "@/util/appointment.progress.util";
import { resolveShouldSkipAttendance } from "@/util/calendar.util";
import { getAttendanceState } from "@/util/student.util";

const AppointmentDisplay = ({ appointment, onClose }: { appointment: ExtendedAppointment; onClose: () => void }) => {
  const {
    apiStore: { calendarApi, formApi, progressApi },
    authStore: { currentUser, timezone },
    calendarStore,
  } = useContext(StoreContext);

  const navigate = useNavigate();
  const navigateAndClose = (url: string) => {
    onClose();
    navigate(url);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [cancelStatus, setCancelStatus] = useState<CancelStatus>();

  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetails>();
  const [isSelectingRecording, setIsSelectingRecording] = useState(false);

  const [appointmentProgress, setAppointmentProgress] = useState<AppointmentProgress>();
  const [isFixingProgress, setIsFixingProgress] = useState(false);

  const [isFixingObservationForm, setIsFixingObservationForm] = useState(false);

  const [statusPendingMakeUp, setStatusPendingMakeUp] = useState<AppointmentStatus>();

  const { completionRequirements } = appointment.appointmentType;
  const shouldCheckProgress = !isEmpty(completionRequirements) && ["ADMIN", "PROVIDER"].includes(currentUser!.userType);
  const failedCompletionRequirements = appointmentProgress
    ? completionRequirements.filter(r => !doesProgressMeetRequirement(appointmentProgress, r))
    : undefined;

  useMountEffect(() => {
    calendarApi.getAppointmentDetails(appointment.appointmentId).then(setAppointmentDetails);
    if (shouldCheckProgress) progressApi.getAppointmentProgress(appointment.appointmentId).then(setAppointmentProgress);
  });

  const observationForm = appointmentDetails?.formTemplates.find(t => t.templateTitle === FormType.SlpaObservation);
  const observationFormSubmission = observationForm?.userSubmissions.find(s => s.userId === appointment.provider.userId)
    ?.submissions[0];

  const isMissingObservationFormResponse = observationForm && !observationFormSubmission?.hasAnswers;

  const triggerObservationNotesForm = async () => {
    if (!observationForm) return;

    const submissionId =
      observationFormSubmission?.submissionId ||
      (await formApi
        .createSubmission(observationForm.formTemplateId, {
          appointmentId: appointment.appointmentId,
          createdBy: appointment.provider.userId,
        })
        .then(r => r.submissionId));

    navigate(`/form/submission/${submissionId}`);
  };

  const studentHasAttendedFlags: boolean[] = appointmentDetails
    ? appointment.students.map(s => getAttendanceState(s, appointmentDetails, timezone).hasAttended)
    : [];

  const isOneStudentAbsent = studentHasAttendedFlags.some(f => !f);
  const areAllStudentsAbsent = isEqual(uniq(studentHasAttendedFlags), [false]);

  const shouldSkipAttendance = resolveShouldSkipAttendance(appointment);

  const resolveShowMakeUpPrompt = ({ isOccurred }: { isOccurred: boolean }) => {
    // never show if appointment does not count toward any service minutes
    if (!appointment.appointmentType.complianceType) return false;

    // if skipping attendance due to compliance type, show unless updated to occurred
    if (shouldSkipAttendance) return !isOccurred;

    // for all other appointments, only show if at least one student is set as absent
    return isOneStudentAbsent;
  };

  if (isEditing) return <AppointmentForm editing={appointment} onClose={() => setIsEditing(false)} />;
  if (cancelStatus) {
    const onSuccess = calendarStore.isShowingCancelled
      ? undefined
      : () => {
          onClose();
          toast.success("Successfully cancelled appointment");
        };
    return (
      <CancelAppointmentForm
        appointment={appointment}
        cancelStatus={cancelStatus}
        onBack={() => setCancelStatus(undefined)}
        onSuccess={onSuccess}
        showMakeUpPrompt={resolveShowMakeUpPrompt({ isOccurred: false })}
      />
    );
  }
  if (isSelectingRecording && appointmentDetails?.recordingFiles) {
    return (
      <AppointmentRecordingSelect
        fileUrls={appointmentDetails.recordingFiles.map(f => f.nexusUrl)}
        onBack={() => setIsSelectingRecording(false)}
      />
    );
  }
  if (isFixingProgress && appointmentProgress) {
    if (failedCompletionRequirements?.includes("PROGRESS_METRIC")) {
      return (
        <AppointmentProgressMetricPrompt
          appointmentId={appointment.appointmentId}
          navigate={navigateAndClose}
          onBack={() => setIsFixingProgress(false)}
        />
      );
    }
    if (failedCompletionRequirements?.includes("PROGRESS_NOTE")) {
      return (
        <AppointmentProgressNotePrompt
          appointment={appointment}
          progress={appointmentProgress}
          onSuccess={progressUpdate => {
            setAppointmentProgress({ ...appointmentProgress, ...progressUpdate });
            if (resolveShowMakeUpPrompt({ isOccurred: true })) {
              setStatusPendingMakeUp("OCCURRED");
            } else if (failedCompletionRequirements.length === 1) {
              calendarStore.updateAppointment(appointment.appointmentId, { appointmentStatus: "OCCURRED" });
            }
          }}
          onBack={() => setIsFixingProgress(false)}
        />
      );
    }
  }
  if (isFixingObservationForm) {
    return (
      <AppointmentObservationFormPrompt
        navigateToForm={triggerObservationNotesForm}
        onBack={() => setIsFixingObservationForm(false)}
      />
    );
  }
  if (statusPendingMakeUp) {
    return (
      <AppointmentAbsenceMakeUpPrompt
        appointmentId={appointment.appointmentId}
        pendingStatus={statusPendingMakeUp}
        onBack={() => setStatusPendingMakeUp(undefined)}
      />
    );
  }

  const { shortCode, url: telehealthMeetingUrl } = getTelehealthJoinDetails(
    config.parallelEnv,
    appointment,
    currentUser?.userId,
  );

  const canEdit = calendarStore.canWriteCalendar(appointment.startTime);

  const onStatusUpdate = async (status: AppointmentStatus | "") => {
    if (CANCEL_STATUSES.includes(status as CancelStatus)) {
      setCancelStatus(status as CancelStatus);
      return;
    } else if (status === "OCCURRED") {
      if (areAllStudentsAbsent && !shouldSkipAttendance) {
        toast.error("Cannot mark appointment as occurred - all students are absent");
        return { status: "error" };
      } else if (!isEmpty(failedCompletionRequirements)) {
        setIsFixingProgress(true);
        return;
      } else if (isMissingObservationFormResponse) {
        setIsFixingObservationForm(true);
        return;
      }
    }
    if (status && resolveShowMakeUpPrompt({ isOccurred: status === "OCCURRED" })) {
      setStatusPendingMakeUp(status);
      return;
    }
    await calendarStore.updateAppointment(appointment.appointmentId, { appointmentStatus: status || null });
  };

  const showOccurredWarning =
    appointment.appointmentStatus !== "OCCURRED" &&
    (!isEmpty(failedCompletionRequirements) || isMissingObservationFormResponse || areAllStudentsAbsent);

  const recordingButtonProps =
    appointmentDetails?.recordingFiles.length === 1
      ? { href: appointmentDetails.recordingFiles[0].nexusUrl, target: "_blank" }
      : { onClick: () => setIsSelectingRecording(true) };

  return (
    <CommonDisplay
      title={appointment.title}
      record={appointment}
      recordName={{ singular: "session", plural: "sessions" }}
      deleteRecord={canEdit ? mode => calendarStore.deleteAppointment(appointment.appointmentId, mode) : undefined}
      otherActions={
        currentUser?.userType === "ADMIN"
          ? [
              {
                icon: <ListIcon />,
                label: "Session Events",
                onClick: () => navigateAndClose(`/calendar/appointment/${appointment.appointmentId}/events`),
              },
            ]
          : undefined
      }
      onEdit={canEdit ? () => setIsEditing(true) : undefined}
      onClose={onClose}
    >
      <>
        <SelectWithStatus
          label="Status"
          options={[
            { key: "", label: "None" },
            {
              key: "OCCURRED",
              label: "Occurred",
              icon: showOccurredWarning ? <ErrorOutlineIcon /> : undefined,
            },
            { key: "CANCELLED", label: "Cancelled" },
            { key: "LATE_CANCELLED", label: "Cancelled (<24 hrs)" },
            { key: "NO_SHOW", label: "No-Show" },
          ]}
          fullWidth
          selectedKey={appointment.appointmentStatus ?? ""}
          onSelect={status => onStatusUpdate(status as AppointmentStatus | "")}
          minWidth={420}
          disabled={!canEdit || !appointmentDetails || (shouldCheckProgress && !appointmentProgress)}
        />
        {appointment.cancelReason && (
          <Typography variant="body2" ml={2} sx={{ fontStyle: "italic", color: "red" }}>
            Reason: {appointment.cancelReason.title}
          </Typography>
        )}

        <Grid container spacing={1}>
          <Grid xs={6}>
            <ProcessButton
              variant="outlined"
              process={() => navigator.clipboard.writeText(telehealthMeetingUrl)}
              startIcon={<ContentCopyIcon />}
              fullWidth
            >
              Copy Session Link
            </ProcessButton>
          </Grid>
          <Grid xs={6}>
            <Link href={telehealthMeetingUrl} rel="noopener noreferrer" target="_blank" sx={{ width: "100%" }}>
              <Button variant="contained" startIcon={<OpenInNewIcon />} fullWidth>
                Join Session {shortCode}
              </Button>
            </Link>
          </Grid>
        </Grid>

        <GreyBox>
          <Stack gap={2}>
            <Stack gap={1}>
              <Typography variant="subtitle1">Students</Typography>
              {appointmentDetails ? (
                appointment.students.map(student => (
                  <StudentAttendanceDisplay
                    appointment={appointment}
                    appointmentDetails={appointmentDetails}
                    student={student}
                    canEdit={canEdit}
                    key={student.userId}
                  />
                ))
              ) : (
                <CircularProgress size={20} />
              )}
            </Stack>

            <Stack gap={1}>
              <Typography variant="subtitle1">Provider</Typography>
              <Stack direction="row" alignItems="center" gap={2}>
                <AccountCircleIcon />
                <Typography variant="body1">{appointment.provider.fullName}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </GreyBox>

        {currentUser && ["ADMIN", "PROVIDER"].includes(currentUser.userType) && (
          <Grid container spacing={1}>
            <Grid xs={6}>
              {!appointmentDetails ? (
                <Button variant="outlined" startIcon={<CircularProgress size={16} />} disabled fullWidth>
                  Loading Notes
                </Button>
              ) : observationForm ? (
                <Button
                  variant="outlined"
                  startIcon={<StickyNote2Icon />}
                  onClick={triggerObservationNotesForm}
                  fullWidth
                >
                  View Observation Notes
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EmojiEventsIcon />}
                  href={`/calendar/appointment/${appointment.appointmentId}/progress`}
                  fullWidth
                >
                  View Session Notes
                </Button>
              )}
            </Grid>
            <Grid xs={6}>
              <Button
                variant="outlined"
                startIcon={appointmentDetails ? <VideoFileIcon /> : <CircularProgress size={20} />}
                disabled={isEmpty(appointmentDetails?.recordingFiles)}
                {...recordingButtonProps}
                fullWidth
              >
                Download Recording
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    </CommonDisplay>
  );
};

export default AppointmentDisplay;
