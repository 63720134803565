import React, { MouseEvent, ReactElement, ReactNode, cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridColumnMenuColumnsItem,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnMenuSortItem,
} from "@mui/x-data-grid-premium";
import { isArray, max, sortBy, sum, uniq } from "lodash";
import { DateTime } from "luxon";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { PaginateState } from "@parallel/vertex/types/shared.types";
import { filterExists } from "@parallel/vertex/util/collection.util";

const DEFAULT_ROW_HEIGHT = 52;
const LIST_ITEM_HEIGHT = 20;

const DEFAULT_STYLES = {
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-main": {
    backgroundColor: "white",
  },
};

export type DataTableColumn<A extends object> = {
  key: keyof A;
  header: string;
  type?: "date" | "datetime" | "list";
  width?: number;
  flexWidth?: number; // https://mui.com/x/react-data-grid/column-dimensions/#fluid-width
  sortable?: boolean;
  hidden?: boolean;
  isID?: boolean;
  href?: (a: A) => string;
  renderCell?: (p: GridRenderCellParams<any, any>) => ReactElement;
};

const defaultRenderCell = <A extends object>(
  params: GridRenderCellParams<any, any>,
  prop: DataTableColumn<A>,
): ReactElement => {
  if (isArray(params.value)) {
    return (
      <Stack>
        {params.value.map((v: any, i: number) => (
          <span key={i}>{v}</span>
        ))}
      </Stack>
    );
  }

  const value =
    params.value instanceof DateTime
      ? params.value.toLocaleString(
          prop.type === "date" ? DateTime.DATE_MED : { ...DateTime.DATETIME_MED_WITH_SECONDS, timeZoneName: "short" },
        )
      : params.value;

  return prop.href ? (
    <Link href={prop.href(params.row)}>
      <button onClick={e => e.stopPropagation()} style={{ all: "unset", cursor: "pointer" }}>
        {value}
      </button>
    </Link>
  ) : (
    <span>{value}</span>
  );
};

const toDataGridColumn = <A extends object>(prop: DataTableColumn<A>): GridColDef => ({
  field: prop.key.toString(),
  headerName: prop.header,
  width: prop.width,
  flex: prop.width ? undefined : (prop.flexWidth ?? 1),
  sortable: prop.sortable ?? true,
  renderCell: (params: GridRenderCellParams<any, any>) =>
    prop.renderCell ? prop.renderCell(params) : defaultRenderCell(params, prop),
  renderHeader: () => <Typography variant="subtitle2">{prop.header}</Typography>,
});

export type DataTablePagination = {
  initialPageSize: number;
  onPageChange: (page: PaginateState) => void;
  totalCount: number;
};

export type DataTableAction = {
  size?: "small" | "full";
  onClick?: (id: string) => unknown;
  href?: (id: string) => string;
  icon: ReactNode;
  label?: string;
  isEnabled?: (id: string) => boolean;
  width?: number;
};

type DataTableProps<A extends object> = {
  columns: DataTableColumn<A>[];
  data?: A[];
  pagination?: DataTablePagination;
  onSelect?: (selected: A[]) => void;
  onSortModelChange?: (sortModel: any) => void;
  actions?: DataTableAction[];
  footer?: () => ReactElement;
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: GridColumnMenuSortItem,
        columnMenuFilterItem: null,
        columnMenuColumnsItem: GridColumnMenuColumnsItem,
        columnMenuPinningItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
      }}
    />
  );
};

const DataTable = <A extends object>({
  columns,
  data,
  pagination,
  onSelect,
  onSortModelChange,
  actions,
  footer,
}: DataTableProps<A>) => {
  const navigate = useNavigate();

  const columnVisibilityModel = columns.reduce(
    (currModel, nextColumn) => (nextColumn.hidden ? { ...currModel, [nextColumn.key]: false } : currModel),
    {},
  );
  const initialState = {
    columns: { columnVisibilityModel },
    pagination: pagination ? { paginationModel: { page: 0, pageSize: pagination.initialPageSize } } : undefined,
  };

  const idKey = columns.find(c => c.isID)?.key;
  const listKeys = columns.filter(c => c.type === "list").map(c => c.key);

  const getRowHeight = ({ model }: GridRowHeightParams) => {
    const maxListLength = max(listKeys.map(k => model[k]?.length || 0));
    const extraLines = maxListLength - 2;
    if (!extraLines || extraLines <= 0) return;
    return DEFAULT_ROW_HEIGHT + extraLines * LIST_ITEM_HEIGHT;
  };

  const dataGridColumns = columns.map(toDataGridColumn);

  if (actions) {
    dataGridColumns.push({
      field: "edit",
      width: sum(actions.map(a => a.width || 40)),
      renderHeader: () => "",
      renderCell: params => (
        <Stack direction="row" justifyContent="center" alignItems="center" height="100%" width="100%">
          {actions.map((a, i) => {
            if (a.isEnabled && !a.isEnabled(params.id.toString())) return;
            const onClick = (e: MouseEvent) => {
              e.stopPropagation();
              if (a.href) {
                navigate(a.href(params.id.toString()));
              } else {
                params.id?.toString() && a.onClick?.(params.id.toString());
              }
            };

            if (a.size === "full")
              return (
                <Button onClick={onClick} startIcon={a.icon} key={i}>
                  {a.label}
                </Button>
              );

            const button = (
              <IconButton onClick={onClick} sx={{ width: "36px", height: "36px", color: "primary.main" }}>
                {a.icon &&
                  React.isValidElement(a.icon) &&
                  cloneElement(a.icon as React.ReactElement<any>, {
                    sx: { width: "20px", height: "20px", color: "primary.main" },
                  })}
              </IconButton>
            );
            return (
              <Tooltip title={<Typography variant="body2">{a.label}</Typography>} key={i}>
                {button}
              </Tooltip>
            );
          })}
        </Stack>
      ),
    });
  }

  if (!data) return <FullCenterBox>Loading</FullCenterBox>;

  return (
    <DataGridPremium
      sx={DEFAULT_STYLES}
      columns={dataGridColumns}
      rows={data}
      initialState={initialState}
      getRowId={idKey ? row => row[idKey] : undefined}
      rowHeight={DEFAULT_ROW_HEIGHT}
      getRowHeight={getRowHeight}
      pagination={!!pagination}
      paginationMode={pagination ? "server" : undefined}
      rowCount={pagination?.totalCount}
      onPaginationModelChange={
        pagination
          ? page => pagination.onPageChange({ pageSize: page.pageSize, offset: page.page * page.pageSize })
          : undefined
      }
      pageSizeOptions={pagination ? sortBy(uniq([25, 50, 100, pagination.initialPageSize])) : []}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      disableRowSelectionOnClick={!onSelect}
      checkboxSelection={!!onSelect}
      onRowSelectionModelChange={ids =>
        onSelect && idKey && onSelect(filterExists(data, r => ids.includes(r[idKey] as string)))
      }
      hideFooter={!pagination}
      slots={{ columnMenu: CustomColumnMenu, footer }}
    />
  );
};

export default DataTable;
