import { capitalize } from "lodash";
import { AVAILABLE_LANGUAGES, LANGUAGE_NAMES, REQUIREMENT_CADENCE_NOUNS } from "../enums/user.enums";
import {
  assessmentServiceRowFields,
  AsssessmentServiceStudentRow,
  cadenceFields,
  CadenceParams,
  CadenceType,
  EntityReference,
  hourlyServiceRowFields,
  HourlyServiceStudentRow,
  studentInfoRowFields,
} from "../types/bulk.upload.types";
import { emailSchema } from "../types/shared.types";

// add some type checking to field param keys to enforce unity
// between these fields and the validation/submit request bodies
type TransformedParamKey = "facilitatorEmail" | "providerEmail";
type SubmitParamKey = keyof (HourlyServiceStudentRow & AsssessmentServiceStudentRow) | TransformedParamKey;

type CommonBulkUploadField = {
  editable?: boolean;
  headerName: string;
  paramKey: SubmitParamKey;
  cadenceParamKey?: {
    type: CadenceType;
    field: keyof CadenceParams;
  };
  cellValidator: (value: string | number) => void;
};

type ValueBulkUploadField = CommonBulkUploadField & {
  type: "string" | "number";
};

type SelectBulkUploadField = CommonBulkUploadField & {
  type: "singleSelect";
  getValueOptions: (props: {
    campuses: EntityReference[];
    serviceLines: EntityReference[];
  }) => { value: string; label: string }[];
};

export type BulkUploadField = ValueBulkUploadField | SelectBulkUploadField;

const studentFirstName: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.firstName.parse(value),
  type: "string",
  headerName: "Student First Name",
  paramKey: "firstName",
};

const studentMiddleName: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.middleName.parse(value),
  type: "string",
  headerName: "Student Middle Name",
  paramKey: "middleName",
};

const studentLastName: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.lastName.parse(value),
  type: "string",
  headerName: "Student Last Name",
  paramKey: "lastName",
};

const dob: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.birthDate.parse(value),
  type: "string",
  headerName: "DOB",
  paramKey: "birthDate",
};

const campus: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.campusId.parse(value),
  type: "singleSelect",
  headerName: "Campus",
  paramKey: "campusId",
  getValueOptions: ({ campuses }) => campuses.map(c => ({ value: c.id, label: c.name })),
};

const studentLanguage: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.language.parse(value),
  type: "singleSelect",
  headerName: "Student Language",
  paramKey: "language",
  getValueOptions: () => AVAILABLE_LANGUAGES.map(value => ({ value, label: LANGUAGE_NAMES[value] })),
};

const studentEmailAddress: BulkUploadField = {
  cellValidator: value => studentInfoRowFields.email.parse(value),
  type: "string",
  headerName: "Student Email Address",
  paramKey: "email",
};

const facilitatorEmailAddress: BulkUploadField = {
  cellValidator: value => value && emailSchema.parse(value),
  type: "string",
  headerName: "Facilitator Email Address",
  paramKey: "facilitatorEmail",
};

const providerEmailAddress: BulkUploadField = {
  cellValidator: value => value && emailSchema.parse(value),
  type: "string",
  headerName: "Provider Email Address",
  paramKey: "providerEmail",
};

const serviceLine: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.serviceLineId.parse(value),
  type: "singleSelect",
  headerName: "Service Line",
  paramKey: "serviceLineId",
  getValueOptions: ({ serviceLines }) => serviceLines.map(s => ({ value: s.id, label: s.name })),
};

const assessmentType: BulkUploadField = {
  cellValidator: value => assessmentServiceRowFields.serviceLineId.parse(value),
  type: "singleSelect",
  headerName: "Assessment Type",
  paramKey: "serviceLineId",
  getValueOptions: ({ serviceLines }) => serviceLines.map(s => ({ value: s.id, label: s.name })),
};

const serviceLanguage: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.serviceLanguage.parse(value),
  type: "singleSelect",
  headerName: "Service Language",
  paramKey: "serviceLanguage",
  getValueOptions: () => AVAILABLE_LANGUAGES.map(value => ({ value, label: LANGUAGE_NAMES[value] })),
};

const cadenceUnitOptions = Object.entries(REQUIREMENT_CADENCE_NOUNS).map(([key, value]) => ({
  value: key,
  label: `${capitalize(value)}s`,
}));

const compensatoryMinutes: BulkUploadField = {
  cellValidator: value => value && cadenceFields.minutes.parse(value),
  type: "number",
  headerName: "Compensatory Minutes",
  paramKey: "cadences",
  cadenceParamKey: { type: "compensantory", field: "minutes" },
};

const compensatoryMinutesCadenceUnit: BulkUploadField = {
  cellValidator: value => cadenceFields.unit.parse(value),
  type: "singleSelect",
  headerName: "Compensatory Minutes Cadence Unit",
  paramKey: "cadences",
  cadenceParamKey: { type: "compensantory", field: "unit" },
  getValueOptions: () => cadenceUnitOptions,
};

const compensatoryMinutesCadenceFrequency: BulkUploadField = {
  cellValidator: value => value && cadenceFields.frequency.parse(value),
  type: "number",
  headerName: "Compensatory Minutes Cadence Frequency",
  paramKey: "cadences",
  cadenceParamKey: { type: "compensantory", field: "frequency" },
};

const directServiceMinutes: BulkUploadField = {
  cellValidator: value => value && cadenceFields.minutes.parse(value),
  type: "number",
  headerName: "Direct Service Minutes",
  paramKey: "cadences",
  cadenceParamKey: { type: "directService", field: "minutes" },
};

const directServiceMinutesCadenceUnit: BulkUploadField = {
  cellValidator: value => cadenceFields.unit.parse(value),
  type: "singleSelect",
  headerName: "Direct Service Minutes Cadence Unit",
  paramKey: "cadences",
  cadenceParamKey: { type: "directService", field: "unit" },
  getValueOptions: () => cadenceUnitOptions,
};

const directServiceMinutesCadenceFrequency: BulkUploadField = {
  cellValidator: value => value && cadenceFields.frequency.parse(value),
  type: "number",
  headerName: "Direct Service Minutes Cadence Frequency",
  paramKey: "cadences",
  cadenceParamKey: { type: "directService", field: "frequency" },
};

const consultMinutes: BulkUploadField = {
  cellValidator: value => value && cadenceFields.minutes.parse(value),
  type: "number",
  headerName: "Consult Minutes",
  paramKey: "cadences",
  cadenceParamKey: { type: "consult", field: "minutes" },
};

const consultMinutesCadenceUnit: BulkUploadField = {
  cellValidator: value => cadenceFields.unit.parse(value),
  type: "singleSelect",
  headerName: "Consult Minutes Cadence Unit",
  paramKey: "cadences",
  cadenceParamKey: { type: "consult", field: "unit" },
  getValueOptions: () => cadenceUnitOptions,
};

const consultMinutesCadenceFrequency: BulkUploadField = {
  cellValidator: value => value && cadenceFields.frequency.parse(value),
  type: "number",
  headerName: "Consult Minutes Cadence Frequency",
  paramKey: "cadences",
  cadenceParamKey: { type: "consult", field: "frequency" },
};

const reevaluationDueDate: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.reevaluationDueDate.parse(value),
  type: "string",
  headerName: "Re-Evaluation Due Date",
  paramKey: "reevaluationDueDate",
};

const parallelServiceStart: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.parallelServiceStart.parse(value),
  type: "string",
  headerName: "Parallel Service Start",
  paramKey: "parallelServiceStart",
};

const parallelServiceEnd: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.parallelServiceEnd.parse(value),
  type: "string",
  headerName: "Parallel Service End",
  paramKey: "parallelServiceEnd",
};

const annualIepDueDate: BulkUploadField = {
  cellValidator: value => hourlyServiceRowFields.annualIepDueDate.parse(value),
  type: "string",
  headerName: "Annual IEP Due Date",
  paramKey: "annualIepDueDate",
};

export const HOURLY_SERVICES_UPLOAD_FIELDS: BulkUploadField[] = [
  studentFirstName,
  studentMiddleName,
  studentLastName,
  dob,
  campus,
  studentLanguage,
  studentEmailAddress,
  facilitatorEmailAddress,
  providerEmailAddress,
  serviceLine,
  serviceLanguage,
  compensatoryMinutes,
  compensatoryMinutesCadenceFrequency,
  compensatoryMinutesCadenceUnit,
  directServiceMinutes,
  directServiceMinutesCadenceFrequency,
  directServiceMinutesCadenceUnit,
  consultMinutes,
  consultMinutesCadenceFrequency,
  consultMinutesCadenceUnit,
  reevaluationDueDate,
  parallelServiceStart,
  parallelServiceEnd,
  annualIepDueDate,
];

const assessmentLanguage: BulkUploadField = {
  cellValidator: value => assessmentServiceRowFields.assessmentLanguage.parse(value),
  type: "singleSelect",
  headerName: "Assessment Language",
  paramKey: "assessmentLanguage",
  getValueOptions: () => AVAILABLE_LANGUAGES.map(value => ({ value, label: LANGUAGE_NAMES[value] })),
};

const assessmentDueDate: BulkUploadField = {
  cellValidator: value => assessmentServiceRowFields.assessmentDueDate.parse(value),
  type: "string",
  headerName: "Assessment Due Date",
  paramKey: "assessmentDueDate",
};

const consentSignedDate: BulkUploadField = {
  cellValidator: value => assessmentServiceRowFields.consentSignedDate.parse(value),
  type: "string",
  headerName: "Consent Signed Date",
  paramKey: "consentSignedDate",
};

export const ASSESSMENT_UPLOAD_FIELDS: BulkUploadField[] = [
  studentFirstName,
  studentMiddleName,
  studentLastName,
  dob,
  campus,
  studentLanguage,
  studentEmailAddress,
  facilitatorEmailAddress,
  providerEmailAddress,
  assessmentType,
  assessmentLanguage,
  assessmentDueDate,
  consentSignedDate,
];

export const getDataGridFieldKey = (field: BulkUploadField) => {
  if (!field.cadenceParamKey) return field.paramKey;

  const { type: cadenceType, field: cadenceField } = field.cadenceParamKey;
  return cadenceField === "minutes"
    ? `${cadenceType}Minutes`
    : `${cadenceType}MinutesCadence${capitalize(cadenceField)}`;
};
