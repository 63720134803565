import { initServiceConfig } from "@parallel/vertex/config/initialize.config";

export default initServiceConfig(import.meta.env, {
  global: {
    sentryEndpoint: "https://69420ba15c36d3ece3179bc29f9983fb@o1183338.ingest.us.sentry.io/4507092126203904",
    parallelFaqUrl: "https://help.parallellearning.com/hc/en-us/sections/10486679387675-FAQ",
    assessmentOrderUrl: "https://go.ernestco.co/login/parallel",
    cleverClientId: "ae15a1c5a00093279537",
    muiPremiumLicenseKey:
      "275f5b8fa340da5342fb3949ab0e7beeTz05NTY5OSxFPTE3NTQ1ODQyMDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
    meetingAppointmentTypeIds: ["bf5d9bd0-bc77-453e-a818-672ce9813fcb", "2786b8c7-ad6b-4488-9b2e-99decc672928"],
  },
  local: {
    nexusServerUrl: "http://localhost:8080",
  },
  preprod: {
    nexusServerUrl: "http://api.parallel-staging.com/pathway",
    cleverSandboxDistrictId: "642c50680efe8db49ba7fb52",
    reportWriterUrl: "https://org.parallel-staging.com/assessment/report",
    featherySdkKey: "091da07c-b1b7-40c2-b718-cd1b0dbdadae",
  },
  prod: {
    nexusServerUrl: "http://api.parallellearning.com/pathway",
    cleverSandboxDistrictId: null,
    reportWriterUrl: "https://org.parallellearning.com/assessment/report",
    featherySdkKey: "f9c2c973-e236-445d-a8c6-db33a8434226",
  },
});
