export const initLogger = (tag: string) => {
  const tagMessage = (message: string) => `[${tag}] ${message}`;
  const error = (message: string, error?: any) => console.error(tagMessage(message), error);
  return {
    error,
    warn: (message: string, error?: any) => console.warn(tagMessage(message), error),
    // TODO report to sentry as well
    reportError: error,
    // TODO handle axios errors
    operationError: (operationName: string, e: any) => error(`unexpected error performing ${operationName}`, e),
  };
};
