import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Form, updateUserId, getFieldValues } from "@feathery/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { FullBox, FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { ExtendedFormSubmission, formAnswersSchema } from "@parallel/vertex/types/form.types";
import ApprovalControls from "@/components/shared/input/ApprovalControls";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import { StoreContext } from "@/stores";
import { useUrlNavigation } from "@/util/router.util";

type UrlParams = { formId: string; submissionId: string };

const FormSubmissionScreen = () => {
  const { submissionId } = useParams() as UrlParams;

  const {
    apiStore: { formApi },
    authStore: { currentUser },
    calendarStore: { currentPayPeriod },
  } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const [submission, setSubmission] = useState<ExtendedFormSubmission>();

  const isCurrentUserCreator = currentUser && submission?.createdBy === currentUser.userId;
  const isFormAppointmentOutsidePayPeriod =
    submission?.appointment && currentPayPeriod ? submission.appointment.startTime < currentPayPeriod.startTime : false;

  const isReadOnly = !isCurrentUserCreator || isFormAppointmentOutsidePayPeriod;

  useEffect(() => {
    setIsLoading(true);
    updateUserId(submissionId);
    formApi
      .getSubmissionById(submissionId)
      .then(setSubmission)
      .catch(e => {
        const message = "Failed to load form submission";
        console.error(message, e);
        toast.error(message);
      })
      .finally(() => setIsLoading(false));
  }, [submissionId]);

  const { navigateBack } = useUrlNavigation();

  const onFormComplete = async () => {
    if (isReadOnly) return;

    const formAnswers = formAnswersSchema.safeParse(getFieldValues())?.data;
    if (!formAnswers) {
      toast.warning("Error parsing form answer data");
      return;
    }

    setIsLoading(true);
    await formApi
      .updateSubmission(submissionId, { formAnswers })
      .then(navigateBack)
      .catch(() => toast.error("Failed to update form"))
      .finally(() => setIsLoading(false));
  };

  const theme = useTheme();
  const customLoader = (
    <FullCenterBox>
      <PuffLoader color={theme.palette.grey[500]} size={75} />
    </FullCenterBox>
  );

  return (
    <PrimaryLayout>
      <FullBox position="relative">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
            pt: 2,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <Button startIcon={<ArrowBackIcon />} onClick={navigateBack}>
            <span>Back</span>
          </Button>

          {submission?.latestApproval && currentUser?.userType === "ADMIN" && (
            <Box>
              <ApprovalControls
                approvalStatus={submission.latestApproval.approvalStatus}
                onStatusUpdate={approvalStatus =>
                  formApi.updateSubmission(submissionId, { approvalStatus }).then(setSubmission)
                }
              />
            </Box>
          )}
        </Stack>

        <FullCenterBox
          sx={{ maxWidth: "max(1024px, calc(100% - 2 * 122px))", alignContent: "center", margin: "0 auto" }}
        >
          {isLoading ? (
            customLoader
          ) : !submission ? (
            <Typography variant="body1">Failed to load form submission</Typography>
          ) : (
            <Form
              formId={submission.featheryFormId}
              style={{ maxHeight: "100%" }}
              className="form-wrapper"
              initialLoader={{
                show: true,
                loader: customLoader,
              }}
              readOnly={isReadOnly}
              onFormComplete={onFormComplete}
              hideTestUI={true}
            />
          )}
        </FullCenterBox>
      </FullBox>
    </PrimaryLayout>
  );
};

export default observer(FormSubmissionScreen);
