import { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { CreateAdminBody, createAdminBodySchema } from "@parallel/vertex/types/user/user.types";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import NameInput from "@/components/user/input/NameInput";
import { StoreContext } from "@/stores";

const AdminForm = ({ onWrite, onClose }: { onWrite: () => void; onClose: () => void }) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState<Partial<CreateAdminBody>>({});

  const formContent = (
    <>
      <NameInput params={params} setParams={setParams} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
    </>
  );

  const validate = (params: Partial<CreateAdminBody>) => createAdminBodySchema.safeParse(params)?.data;

  const onSubmit = async (body: CreateAdminBody) => {
    await userApi.createAdmin(body);
    onWrite();
  };

  return (
    <SubmitForm
      recordName="admin"
      operationName="create"
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default AdminForm;
