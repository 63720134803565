import { useContext, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { ObjectiveType } from "@parallel/vertex/types/progress.types";
import { ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { CreateStudentBody, createStudentBodySchema } from "@parallel/vertex/types/user/student.types";
import MultipleGoalInput from "@/components/progress/form/MultipleGoalInput";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import StepForm, { FormStep } from "@/components/shared/layout/StepForm";
import StudentInfoInput from "@/components/user/student/form/StudentInfoInput";
import StudentServiceInput from "@/components/user/student/form/StudentServiceInput";
import { StoreContext } from "@/stores";
import { parseGoalsBody } from "@/util/progress.form.util";
import { StudentParams, getInitialStudentParams, parseInfoBody, parseServicesBody } from "@/util/student.form.util";

const StudentCreateForm = ({ onWrite, onClose }: { onWrite: () => void; onClose: () => void }) => {
  const {
    apiStore: { progressApi, serviceApi, userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [params, setParams] = useState(getInitialStudentParams());

  const [allRequirementTypes, setAllRequirementTypes] = useState<ServiceRequirementType[]>([]);
  const [allObjectiveTypes, setAllObjectiveTypes] = useState<ObjectiveType[]>([]);

  useMountEffect(() => {
    serviceApi.searchServiceRequirementTypes().then(setAllRequirementTypes);
    progressApi.getAllObjectiveTypes().then(setAllObjectiveTypes);
  });

  const infoFields = (
    <>
      <StudentInfoInput params={params} setParams={setParams} />
      {currentUser?.userType === "ADMIN" && (
        <AutoCompletePageSearchInput
          label="Providers (optional)"
          search={keyword => userApi.searchProviders({ keyword })}
          getOption={u => ({ key: u.userId, label: u.fullName })}
          selected={params.providers}
          onSelect={providers => setParams({ ...params, providers })}
        />
      )}
      <AutoCompletePageSearchInput
        label="Facilitators (optional)"
        search={keyword => userApi.searchFacilitators({ keyword })}
        getOption={u => ({ key: u.userId, label: u.fullName })}
        selected={params.facilitators}
        onSelect={facilitators => setParams({ ...params, facilitators })}
      />
    </>
  );

  const steps: FormStep[] = [
    {
      label: "Info",
      content: infoFields,
      isValid: !!parseInfoBody(params),
    },
    {
      label: "Services",
      content: <StudentServiceInput params={params} setParams={setParams} allRequirementTypes={allRequirementTypes} />,
      isValid: !!parseServicesBody(params, allRequirementTypes),
    },
    {
      label: "Goals (optional)",
      content: <MultipleGoalInput params={params} setParams={setParams} allObjectiveTypes={allObjectiveTypes} />,
    },
  ];

  const validate = (params: StudentParams): CreateStudentBody | undefined =>
    createStudentBodySchema.safeParse({
      ...parseInfoBody(params),
      ...parseServicesBody(params, allRequirementTypes),
      ...parseGoalsBody(params, allObjectiveTypes),
    })?.data;

  const onSubmit = async (body: CreateStudentBody) => {
    await userApi.createStudent(body);
    onWrite();
  };

  return (
    <StepForm
      recordName="student"
      operationName="create"
      params={params}
      steps={steps}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default StudentCreateForm;
