import { useContext } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { flatMap, max, min, partition, pick, sortBy } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { isOverlap, isWithin } from "@parallel/vertex/util/datetime.util";
import { getInitials } from "@parallel/vertex/util/string.util";
import CalendarItem, { CalendarItemData } from "@/components/calendar/CalendarItem";
import { StoreContext } from "@/stores";
import { useUrlNavigation } from "@/util/router.util";

type OverlapGroup = {
  items: CalendarItemData[];
  startTime: DateTime;
  endTime: DateTime;
};

type PositionedItemData = {
  data: CalendarItemData;
  boxProps: {
    top: string;
    left: string;
    width: string;
    height: string;
  };
  isWithinAvailability: boolean;
};

const groupOverlaps = (data: CalendarItemData[]): OverlapGroup[] =>
  sortBy(data, "startTime").reduce((currGroups, nextItem) => {
    const overlapGroupIndex = currGroups.findIndex(group => isOverlap(group, nextItem));
    if (overlapGroupIndex === -1)
      return [...currGroups, { items: [nextItem], ...pick(nextItem, "startTime", "endTime") }];

    const overlapGroup = currGroups[overlapGroupIndex];
    const updatedGroup: OverlapGroup = {
      items: [...overlapGroup.items, nextItem],
      startTime: min([overlapGroup.startTime, nextItem.startTime]) || overlapGroup.startTime,
      endTime: max([overlapGroup.endTime, nextItem.endTime]) || overlapGroup.endTime,
    };
    return currGroups.map((g, i) => (i === overlapGroupIndex ? updatedGroup : g));
  }, [] as OverlapGroup[]);

const CalendarColumn = ({ date }: { date: DateTime }) => {
  const {
    calendarStore: { calendarItems: items, hourRange },
  } = useContext(StoreContext);

  const { navigate } = useUrlNavigation();

  const startTime = date.set({ hour: hourRange.min }).startOf("hour");
  const endTime = date.set({ hour: hourRange.max }).startOf("hour");
  const totalMinutes = (hourRange.max - hourRange.min) * 60;

  // TODO add some UX for these instead of just hiding them
  const filterItems = <A extends DateTimeRange>(items: A[]) => items.filter(i => isWithin({ startTime, endTime }, i));

  const appointmentData: CalendarItemData[] = filterItems(items.appointments).map(a => ({
    ...a,
    key: a.appointmentId,
    status: a.appointmentStatus || undefined,
    attendees: a.students.map(s => ({ ...s, initials: getInitials(s) })),
    onClick: () => navigate(`/calendar/appointment/${a.appointmentId}`, { keepQuery: true }),
  }));
  const indirectTimeData: CalendarItemData[] = filterItems(items.indirectTime).map(t => ({
    ...t,
    title: t.taskType.title,
    key: t.timeEntryId,
    status: t.latestApproval?.approvalStatus || "APPROVED",
    attendees: [],
    onClick: () => navigate(`/calendar/time/${t.timeEntryId}`, { keepQuery: true }),
  }));

  const relativeVerticalLength = (startTime: DateTime, endTime: DateTime) => {
    const proportion = endTime.diff(startTime, "minutes").minutes / totalMinutes;
    return percentString(proportion);
  };

  const getVerticalBoxProps = (data: { startTime: DateTime; endTime: DateTime }) => ({
    top: relativeVerticalLength(startTime, data.startTime),
    height: relativeVerticalLength(data.startTime, data.endTime),
  });

  const positionedItemData: PositionedItemData[] = flatMap(
    groupOverlaps([...appointmentData, ...indirectTimeData]),
    dataGroup => {
      const widthProportion = 1 / dataGroup.items.length;
      const sortedItems = sortBy(dataGroup.items, "startTime");
      return sortedItems.map((data, i) => ({
        data,
        boxProps: {
          ...getVerticalBoxProps(data),
          left: percentString(widthProportion * i),
          width: percentString(widthProportion),
        },
        isWithinAvailability: items.availabilities.some(a => isOverlap(a, sortedItems[0])),
      }));
    },
  );

  const availabilities = filterItems(items.availabilities).map(a => ({
    key: a.availabilityId,
    boxProps: getVerticalBoxProps(a),
    onClick: () => navigate(`/calendar/availability/${a.availabilityId}`, { keepQuery: true }),
  }));

  const [offsetItems, flushItems] = partition(positionedItemData, d => d.isWithinAvailability);

  const renderItem = ({ data, boxProps }: PositionedItemData) => (
    <Box {...boxProps} position="absolute" paddingY="3px" paddingX="1px" key={data.key}>
      <CalendarItem data={data} />
    </Box>
  );

  return (
    <FullBox sx={{ paddingLeft: 4, paddingRight: "2px" }}>
      {availabilities.map(({ boxProps, onClick, key }) => (
        <Box
          {...boxProps}
          position="absolute"
          left={0}
          width="100%"
          padding="1px"
          sx={{ border: 2, borderColor: "primary.main" }}
          borderRadius="4px"
          key={key}
        >
          <Stack
            width="28px"
            height="100%"
            borderRadius="4px"
            bgcolor="grey.100"
            alignItems="center"
            py={0.75}
            onClick={onClick}
            sx={{ cursor: "pointer" }}
          >
            <EventAvailableIcon color="primary" sx={{ width: "0.875em", height: "0.875em" }} />
          </Stack>
        </Box>
      ))}
      <FullBox position="relative">{offsetItems.map(renderItem)}</FullBox>
      {flushItems.map(renderItem)}
    </FullBox>
  );
};

export default observer(CalendarColumn);
