import { ReactNode, useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { groupBy, isEmpty, uniqBy } from "lodash";
import { DateTime } from "luxon";
import { stepValuesSchema } from "@parallel/polygon/components/progress/metric/AccuracyInput";
import AutoCompleteInput, { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { FullGrid, FullStack } from "@parallel/polygon/components/shared/layout/container";
import {
  ObjectiveMetricValue,
  SingleStudentAppointmentProgress,
  StudentGoal,
  StudentObjective,
} from "@parallel/vertex/types/progress.types";
import { DateTimeRange, PaginatedResult, PaginateState } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toLocalDate } from "@parallel/vertex/util/datetime.util";
import { secondsToString, toPercentString } from "@parallel/vertex/util/number.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import PaginationControl from "@/components/shared/navigation/PaginationControl";
import LoadingScreen from "@/screens/LoadingScreen";
import { StoreContext } from "@/stores";

const NAME_SELECT_GROUPS = {
  goals: "Goals",
  objectives: "Objectives",
} as const;

type NameSelectGroup = (typeof NAME_SELECT_GROUPS)[keyof typeof NAME_SELECT_GROUPS];

const AppointmentEntryRow = ({ leftContent, rightContent }: { leftContent: ReactNode; rightContent?: ReactNode }) => (
  <FullGrid container borderBottom={1} borderColor="grey.300" px={3} py={2}>
    <Grid xs={6}>{leftContent}</Grid>
    <Grid xs={6} pl={4}>
      {rightContent}
    </Grid>
  </FullGrid>
);

const ObjectiveTitleContent = ({
  objective,
  objectiveIndex,
  goal,
  goalIndex,
}: {
  objective: StudentObjective;
  objectiveIndex: number;
  goal: StudentGoal;
  goalIndex: number;
}) => (
  <Stack gap={2}>
    <Stack>
      <Typography variant="h3">
        {toTitleCase(objective.category)} Objective {goalIndex}.{objectiveIndex}
      </Typography>
      <Typography variant="body2">{objective.description}</Typography>
    </Stack>
    <Stack>
      <Typography variant="h3">Goal {goalIndex}</Typography>
      <Typography variant="body2">{goal.description}</Typography>
    </Stack>
  </Stack>
);

const MetricEntryContent = ({ objective, metric }: { objective: StudentObjective; metric: ObjectiveMetricValue }) => {
  if (metric.type === "number") {
    switch (objective.category) {
      case "ACCURACY":
        const steps = stepValuesSchema.safeParse(metric.metadata)?.data;
        return (
          metric.type === "number" && (
            <Stack direction="row" gap={4}>
              <Typography variant="body2">{toPercentString(metric.value)}</Typography>
              {steps && (
                <Stack direction="row" gap={2}>
                  <Typography variant="body2">{steps.correct} Correct</Typography>
                  <Typography variant="body2">{steps.incorrect} Incorrect</Typography>
                  <Typography variant="body2">{steps.prompt} Prompt</Typography>
                </Stack>
              )}
            </Stack>
          )
        );
      case "DURATION":
        return <Typography variant="body2">{secondsToString(metric.value)} Duration</Typography>;
      case "DECREASING_FREQUENCY":
      case "INCREASING_FREQUENCY":
      case "PROMPTING_FREQUENCY":
        return <Typography variant="body2">{metric.value} Occurrences</Typography>;
    }
  } else if (objective.category === "ANECDOTAL") {
    return <Typography variant="body2">{metric.value}</Typography>;
  }

  return (
    <Typography variant="body2" fontStyle="italic">
      Invalid Data
    </Typography>
  );
};

const AppointmentEntry = ({
  progress,
  itemFilter,
  showCompleted,
  showArchived,
}: {
  progress: SingleStudentAppointmentProgress;
  itemFilter: SelectOption | null;
  showCompleted: boolean;
  showArchived: boolean;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const shouldOmit = (group: NameSelectGroup, itemKey: string, isItemComplete: boolean, isItemArchived: boolean) =>
    (itemFilter?.groupName === group && itemFilter.key !== itemKey) ||
    (!showCompleted && isItemComplete) ||
    (!showArchived && isItemArchived);

  const objectiveRowProps = progress.goals.flatMap((goal, i) => {
    if (shouldOmit(NAME_SELECT_GROUPS.goals, goal.goalId, !!goal.completedAt, goal.isArchived)) return [];
    return mapExists(goal.objectives, (objective, j) => {
      if (
        !objective.metric ||
        shouldOmit(NAME_SELECT_GROUPS.objectives, objective.objectiveId, !!objective.completedAt, objective.isArchived)
      )
        return;
      return {
        leftContent: (
          <ObjectiveTitleContent objective={objective} objectiveIndex={j + 1} goal={goal} goalIndex={i + 1} />
        ),
        rightContent: <MetricEntryContent objective={objective} metric={objective.metric} />,
      };
    });
  });

  return (
    <Stack width="100%">
      <Box bgcolor="grey.200" px={2} py={1}>
        <Typography variant="body1">
          {progress.appointment.startTime.setZone(timezone).toLocaleString(DateTime.DATETIME_FULL)} -{" "}
          {progress.appointment.title}
        </Typography>
      </Box>

      {isEmpty(objectiveRowProps) && !progress.note && !progress.groupNote ? (
        <AppointmentEntryRow
          leftContent={
            <Typography variant="body2" fontStyle="italic">
              No Data
            </Typography>
          }
        />
      ) : (
        <>
          {progress.note && (
            <AppointmentEntryRow
              leftContent={<Typography variant="h3">Student Notes</Typography>}
              rightContent={<Typography variant="body2">{progress.note}</Typography>}
            />
          )}
          {progress.groupNote && (
            <AppointmentEntryRow
              leftContent={<Typography variant="h3">Group Notes</Typography>}
              rightContent={<Typography variant="body2">{progress.groupNote}</Typography>}
            />
          )}
          {objectiveRowProps.map((props, i) => (
            <AppointmentEntryRow {...props} key={i} />
          ))}
        </>
      )}
    </Stack>
  );
};

const StudentProgressReport = ({ studentId }: { studentId: string }) => {
  const {
    apiStore: { progressApi },
    authStore: { timezone },
  } = useContext(StoreContext);

  const currentDate = toLocalDate(DateTime.now(), timezone);
  const [timeRange, setTimeRange] = useState<Partial<DateTimeRange>>({
    startTime: currentDate.minus({ days: 7 }),
    endTime: currentDate,
  });
  const isTimeRangeValid = timeRange.startTime && timeRange.endTime;

  const [pageState, setPageState] = useState<PaginateState>({ pageSize: 20, offset: 0 });

  const [progressPage, setProgressPage] = useState<PaginatedResult<SingleStudentAppointmentProgress>>();
  useEffect(() => {
    if (!isTimeRangeValid) return;
    progressApi
      .searchStudentProgress(studentId, {
        startTime: timeRange.startTime,
        endTime: timeRange.endTime?.plus({ days: 1 }),
        ...pageState,
      })
      .then(page => {
        setProgressPage(page);
        if (page.totalCount < pageState.offset) setPageState({ pageSize: 20, offset: 0 });
      });
  }, [timeRange, isTimeRangeValid, pageState]);

  const [itemFilter, setItemFilter] = useState<SelectOption | null>(null);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showArchived, setShowArchived] = useState(false);

  let content = isTimeRangeValid ? (
    <LoadingScreen />
  ) : (
    <Typography variant="body1" p={2}>
      Select a valid time range above
    </Typography>
  );
  let nameOptions: SelectOption[] = [];

  if (progressPage) {
    if (progressPage.totalCount === 0) {
      content = <Typography p={2}>No Matches Found</Typography>;
    } else {
      content = (
        <FullStack gap={2}>
          {progressPage.records.map(progress => (
            <AppointmentEntry
              progress={progress}
              itemFilter={itemFilter}
              showCompleted={showCompleted}
              showArchived={showArchived}
              key={progress.appointment.id}
            />
          ))}
        </FullStack>
      );
      const allOptions = progressPage.records.flatMap(progress =>
        progress.goals.flatMap(goal => [
          { key: goal.goalId, label: goal.description, value: goal.goalId, groupName: NAME_SELECT_GROUPS.goals },
          ...goal.objectives.map(objective => ({
            key: objective.objectiveId,
            label: objective.description,
            groupName: NAME_SELECT_GROUPS.objectives,
          })),
        ]),
      );
      const uniqueOptions = groupBy(uniqBy(allOptions, "key"), "groupName");

      const goals = uniqueOptions[NAME_SELECT_GROUPS.goals] || [];
      const objectives = uniqueOptions[NAME_SELECT_GROUPS.objectives] || [];

      nameOptions = [...goals, ...objectives];
    }
  }

  return (
    <FullStack pt={2}>
      <Stack pb={2} direction="row" alignItems="center" justifyContent="space-between">
        <AutoCompleteInput
          label="Goal / Objective Name"
          options={nameOptions}
          selected={itemFilter}
          onSelect={setItemFilter}
          size="small"
          width={400}
        />
        <Stack gap={2} direction="row" alignItems="center">
          <FormControlLabel
            label="Show Completed"
            control={<Switch checked={showCompleted} onChange={e => setShowCompleted(e.target.checked)} />}
          />
          <FormControlLabel
            label="Show Archived"
            control={<Switch checked={showArchived} onChange={e => setShowArchived(e.target.checked)} />}
          />
          <DatePicker
            label="Start Date"
            value={timeRange.startTime || null}
            onChange={d => setTimeRange({ ...timeRange, startTime: d ? toLocalDate(d, timezone) : undefined })}
            slotProps={{ textField: { size: "small" } }}
          />
          <DatePicker
            label="End Date"
            value={timeRange.endTime || null}
            onChange={d => setTimeRange({ ...timeRange, endTime: d ? toLocalDate(d, timezone) : undefined })}
            slotProps={{ textField: { size: "small" } }}
          />
        </Stack>
      </Stack>

      <Box sx={{ width: "100%", flex: "1 1 0%", overflowY: "auto" }}>{content}</Box>

      {progressPage && (
        <PaginationControl pageState={pageState} setPageState={setPageState} totalCount={progressPage.totalCount} />
      )}
    </FullStack>
  );
};

export default StudentProgressReport;
