import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Stack } from "@mui/material";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { resolveObjectiveCompletionToggleUpdate } from "@parallel/polygon/util/progress.util";
import {
  StudentGoal,
  StudentObjective,
  UpdateGoalBody,
  UpdateObjectiveBody,
} from "@parallel/vertex/types/progress.types";
import { StoreContext } from "@/stores";

const ObjectiveActions = ({
  objective,
  studentId,
  goal,
  onGoalUpdated,
}: {
  objective: StudentObjective;
  studentId: string;
  goal: StudentGoal;
  onGoalUpdated: (g: StudentGoal) => void;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const updateObjective = (update: UpdateObjectiveBody, goalUpdate?: UpdateGoalBody) =>
    progressApi
      .updateStudentGoal(studentId, goal.goalId, {
        ...goalUpdate,
        objectives: [{ objectiveId: objective.objectiveId, ...update }],
      })
      .then(onGoalUpdated);

  const setObjectiveCompleted = (isCompleted: boolean) => {
    const update = resolveObjectiveCompletionToggleUpdate(objective.objectiveId, isCompleted, goal);
    return progressApi.updateStudentGoal(studentId, goal.goalId, update).then(onGoalUpdated);
  };

  const isCompleted = !!objective.completedAt;

  return (
    <Stack direction="row" gap={1}>
      <ProcessButton
        process={() => updateObjective({ isArchived: !objective.isArchived })}
        startIcon={objective.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
        key="archive"
      >
        {objective.isArchived ? "Unarchive" : "Archive"}
      </ProcessButton>
      {!objective.isArchived &&
        (isCompleted ? (
          <ProcessButton process={() => setObjectiveCompleted(false)} startIcon={<LockOpenIcon />} key="re-open">
            Re-Open
          </ProcessButton>
        ) : (
          <ProcessButton
            process={() => setObjectiveCompleted(true)}
            startIcon={<CheckCircleOutlineIcon />}
            key="complete"
          >
            Complete
          </ProcessButton>
        ))}
    </Stack>
  );
};

export default ObjectiveActions;
