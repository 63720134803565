import { LanguageType, Report } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { ReportType } from "../../enums/report.enums";
import { ORDERED_LANGUAGES } from "../../enums/user.enums";
import { datetimeRequestField } from "../shared.types";
import { ExtendedUser } from "../user/user.types";

export type ServiceReportType = {
  reportType: ReportType;
  serviceLine: { id: string; title: string; languages: LanguageType[] };
};

export type ServiceReportEnrollment = ServiceReportType & {
  serviceLineClientId: string;
  consentSignedAt?: DateTime;
  assessmentDueDate?: DateTime;
  eligibleReviewers: { userId: string; name: string }[];
};

// deprecated
export type ReportStudentDetails = {
  enrolledReportType?: ServiceReportType & { consentSignedAt?: DateTime };
  allReportTypes: ServiceReportType[];
};

export type ReportStudent = ExtendedUser & { enrollment: ServiceReportEnrollment };

export const createReportBodySchema = z.object({
  serviceLineClientId: z.string().uuid(),
  serviceLineId: z.string().uuid().optional(),
  languages: z.enum(ORDERED_LANGUAGES).array().min(1),
  reviewerId: z.string().uuid(),
  assessmentDueDate: datetimeRequestField,
  consentSignedAt: datetimeRequestField.optional(),
});
export type CreateReportBody = z.infer<typeof createReportBodySchema>;

export type CreateReportResponse = { student: ReportStudent; report: Report };
