import {
  Client,
  ComplianceType,
  LanguageType,
  RequirementCadence,
  ServiceLine,
  ServiceRequirement,
  ServiceRequirementDataType,
  UserType,
} from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { ReportType } from "../../enums/report.enums";
import { ORDERED_LANGUAGES, ORDERED_REQUIREMENT_CADENCES } from "../../enums/user.enums";
import { StudentGoal, createGoalBodySchema } from "../progress.types";
import {
  emailSchema,
  Override,
  datetimeRequestField,
  sortQueryFields,
  paginateQueryFields,
  positiveIntegerSchema,
} from "../shared.types";
import { ExtendedUser } from "./user.types";

const nullableEmailSchema = z.preprocess(input => (input === "" ? null : input), emailSchema.optional().nullable());

type ExtendedStudentClient = Override<Omit<Client, "clientId">, { birthDate?: DateTime }>;

export type StudentRelatedUser = { userId: string; fullName: string; email?: string };

type TruncatedStudentService = { id: string; title: string };

export type ExtendedStudentUser = ExtendedUser &
  ExtendedStudentClient & {
    languages: LanguageType[];
    providers: StudentRelatedUser[];
    facilitators: StudentRelatedUser[];
    activeServices: TruncatedStudentService[];
    campus?: { id: string; name: string };
    district?: { id: string; name: string };
    archiveReason?: string;
  };

export type StudentServiceRequirement = ServiceRequirement & {
  title: string;
  dataType: ServiceRequirementDataType;
};

type CommonRequirement = { requirementTypeId: string; requirementTitle: string };

export type ServiceCadenceRequirement = CommonRequirement & {
  minutes: number;
  repeat: { size: number; unit: RequirementCadence } | null;
  complianceType?: ComplianceType;
  minutesRequired?: number;
  minutesOffered?: number;
  minutesAttended?: number;
};

export type StudentServiceRequirements = {
  cadences: ServiceCadenceRequirement[];
  dates: (CommonRequirement & { value: DateTime; type: "START_DATE" | "END_DATE" | "DATE" })[];
  users: (CommonRequirement & { userType: UserType; userId: string; fullName: string })[];
};

export type StudentService = ServiceLine & {
  serviceClientId: string;
  languages: LanguageType[];
  requirements: StudentServiceRequirements;
  reportTypes: ReportType[];
};

export type SingleStudentUser = ExtendedStudentUser & {
  services: StudentService[];
  goals: StudentGoal[];
};

const stringToBooleanSchema = z.string().transform(value => value === "true");

export const searchStudentQuerySchema = z.object({
  name: z.string().trim().optional(),
  email: nullableEmailSchema,
  keyword: z.string().trim().optional(),
  providerId: z.string().uuid().optional(),
  facilitatorId: z.string().uuid().optional(),
  slpaId: z.string().uuid().optional(),
  providerKeyword: z.string().trim().optional(),
  facilitatorKeyword: z.string().trim().optional(),
  campusName: z.string().trim().optional(),
  institutionKeyword: z.string().trim().optional(),
  showTestData: stringToBooleanSchema.optional(),
  showArchivedStudents: stringToBooleanSchema.optional(),
  filterUnassignedStudents: stringToBooleanSchema.optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchStudentQuery = z.infer<typeof searchStudentQuerySchema>;

const createStudentBodyInfoFields = {
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  birthDate: datetimeRequestField,
  campusId: z.string().uuid(),
  email: nullableEmailSchema,
  languages: z.enum(ORDERED_LANGUAGES).array().min(1),
  providerIds: z.string().uuid().array().optional(),
  facilitatorIds: z.string().uuid().array().optional(),
};

export const createStudentInfoBodySchema = z.object(createStudentBodyInfoFields);

export type CreateStudentInfoBody = z.infer<typeof createStudentInfoBodySchema>;

export const studentServiceCadenceRequestSchema = z
  .object({
    minutes: positiveIntegerSchema.optional(),
    size: positiveIntegerSchema.optional(),
    unit: z.enum(ORDERED_REQUIREMENT_CADENCES).optional(),
    totalMinutesOverride: positiveIntegerSchema.optional(),
  })
  .refine(({ minutes, size, unit, totalMinutesOverride }) => (minutes && size && unit) || totalMinutesOverride);

export type StudentServiceCadenceRequest = z.infer<typeof studentServiceCadenceRequestSchema>;

const studentServiceRequestSchema = z.object({
  serviceLineId: z.string().uuid(),
  languages: z.enum(ORDERED_LANGUAGES).array().min(1),
  cadences: z.record(z.string().uuid(), studentServiceCadenceRequestSchema).optional(),
  dates: z.record(z.string().uuid(), datetimeRequestField).optional(),
  users: z.record(z.string().uuid(), z.string().uuid()).optional(),
});

export type StudentServiceRequest = z.infer<typeof studentServiceRequestSchema>;

const createStudentBodyServicesFields = {
  services: studentServiceRequestSchema.array().min(1),
};

export const createStudentServicesBodySchema = z.object(createStudentBodyServicesFields);

export type CreateStudentServicesBody = z.infer<typeof createStudentServicesBodySchema>;

const createStudentGoalsBodyFields = {
  goals: createGoalBodySchema.array(),
};

export const createStudentGoalsBodySchema = z.object(createStudentGoalsBodyFields);

export type CreateStudentGoalsBody = z.infer<typeof createStudentGoalsBodySchema>;

export const createStudentBodySchema = z.object({
  ...createStudentBodyInfoFields,
  ...createStudentBodyServicesFields,
  ...createStudentGoalsBodyFields,
});

export type CreateStudentBody = z.infer<typeof createStudentBodySchema>;

export const updateStudentBodySchema = createStudentBodySchema.partial();

export type UpdateStudentBody = z.infer<typeof updateStudentBodySchema>;

export const ASSIGNMENT_TYPES = ["facilitator", "provider"] as const;
export type AssignmentType = (typeof ASSIGNMENT_TYPES)[number];

export const updateStudentAssignmentBodySchema = z.object({
  studentIds: z.string().uuid().array(),
  type: z.enum(ASSIGNMENT_TYPES),
  addUserIds: z.string().uuid().array().optional(),
  removeUserIds: z.string().uuid().array().optional(),
});

export type UpdateStudentAssignmentBody = z.infer<typeof updateStudentAssignmentBodySchema>;

export const archiveStudentBodySchema = z.object({ reasonId: z.string().uuid() });

export type ArchiveStudentBody = z.infer<typeof archiveStudentBodySchema>;
