import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { AccordionDetails, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { pick } from "lodash";
import { DateTime } from "luxon";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { ReportStudent } from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult, PaginateParams } from "@parallel/vertex/types/shared.types";
import CreateReportForm from "@/components/report/CreateReportForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import DataTable, { DataTableColumn } from "@/components/shared/layout/DataTable";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import { StoreContext } from "@/stores";

type ReportStudentRow = ReportStudent & {
  serviceLineClientId: string;
  serviceType: string;
  consentSignedAt?: DateTime;
  assessmentDueDate?: DateTime;
};

const ASSIGNED_REPORT_COLUMNS: DataTableColumn<ReportStudentRow>[] = [
  {
    key: "serviceLineClientId",
    header: "Service Enrollment ID",
    hidden: true,
    isID: true,
  },
  { key: "userId", header: "ID", hidden: true },
  {
    key: "fullName",
    header: "Name",
  },
  {
    key: "serviceType",
    header: "Service Type",
    flexWidth: 2,
  },
  {
    key: "consentSignedAt",
    header: "Consent Signed At",
    type: "date",
  },
  {
    key: "assessmentDueDate",
    header: "Assessment Due Date",
    type: "date",
  },
];

const parseReportStudentRow = (s: ReportStudent) => ({
  ...s,
  serviceType: s.enrollment.serviceLine.title,
  ...pick(s.enrollment, "serviceLineClientId", "consentSignedAt", "assessmentDueDate"),
});

const ReportHomeScreen = () => {
  const {
    apiStore: { reportApi },
  } = useContext(StoreContext);

  const header = <SubHeader icon={<AssessmentIcon />} title="Assessments" />;

  const [readyStudentsPage, setReadyStudentsPage] = useState<PaginatedResult<ReportStudent>>();

  // TODO implement fetch that only includes users without a report and assigned to an assessment service line and returns service line dates
  const fetchReadyStudents = (page: PaginateParams = { pageSize: 25 }) =>
    reportApi.searchEligibleReportStudents(page).then(setReadyStudentsPage);

  useEffect(() => {
    fetchReadyStudents();
  }, []);

  const [createReportStudent, setCreateReportStudent] = useState<ReportStudent>();

  const navigate = useNavigate();

  return (
    <PrimaryLayout headerContent={header}>
      <FullBox sx={{ py: 2 }}>
        <Accordion>
          <AccordionSummary>
            <Typography variant="h3">Assigned Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              columns={ASSIGNED_REPORT_COLUMNS}
              data={readyStudentsPage?.records.map(parseReportStudentRow)}
              pagination={{
                initialPageSize: 25,
                onPageChange: fetchReadyStudents,
                totalCount: readyStudentsPage?.totalCount || 0,
              }}
              actions={[
                {
                  size: "full",
                  icon: <AddchartIcon />,
                  label: "Create Assessment",
                  onClick: id => setCreateReportStudent(readyStudentsPage?.records.find(s => s.userId === id)),
                  width: 200,
                },
              ]}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography variant="h3">Active Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>TODO</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography variant="h3">Completed Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>TODO</AccordionDetails>
        </Accordion>
      </FullBox>

      <CenterModal isOpen={!!createReportStudent} onClose={() => setCreateReportStudent(undefined)} minWidth={400}>
        {createReportStudent && (
          <CreateReportForm
            student={createReportStudent}
            onCreated={reportId => navigate(`/assessment/report/${reportId}`)}
            onClose={() => setCreateReportStudent(undefined)}
          />
        )}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default ReportHomeScreen;
