import { isEmpty } from "lodash";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { ExtendedRecurrence } from "@parallel/vertex/types/calendar/recurrence.types";
import { TaskTypeCategory } from "@parallel/vertex/types/calendar/time.types";
import { getCommaList, toTitleCase } from "@parallel/vertex/util/string.util";
import config from "@/config";

export const DEFAULT_MIN_CALENDAR_HOUR = 6;
export const DEFAULT_MAX_CALENDAR_HOUR = 19;

type TaskTypeGroupConfig = {
  title: string;
  student?: "required" | "single-optional";
  campus?: "required";
};

export const TYPE_CATEGORY_CONFIG: Record<TaskTypeCategory, TaskTypeGroupConfig> = {
  ASSESSMENT: {
    title: "Assessments and Evaluations",
    student: "required",
  },
  CASE_MANAGEMENT: {
    title: "Case Management",
    student: "required",
  },
  PARALLEL_ACTIVITY: {
    title: "Parallel Activities",
  },
  SCHOOL_MEETING: {
    title: "School Meetings",
    campus: "required",
  },
  THERAPY: {
    title: "Session Delivery",
    student: "single-optional",
    campus: "required",
  },
};

export const recurrenceText = (recurrence: ExtendedRecurrence) => {
  const periodUnitName = recurrence.periodUnit.toLowerCase();
  const periodPart = recurrence.periodSize === 1 ? periodUnitName : `${recurrence.periodSize} ${periodUnitName}s`;
  const daysPart = isEmpty(recurrence.daysOfWeek) ? "" : ` on ${getCommaList(recurrence.daysOfWeek.map(toTitleCase))}`;

  return `Repeats every ${periodPart}${daysPart}`;
};

export const resolveShouldSkipAttendance = (appointment: ExtendedAppointment) =>
  appointment.appointmentType.complianceType === "CONSULT" ||
  config.meetingAppointmentTypeIds.includes(appointment.appointmentTypeId);
