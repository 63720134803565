import { createContext } from "react";
import { CalendarStore } from "@/stores/calendar.store";
import { ApiStore } from "./api.store";
import { AuthStore } from "./auth.store";

const apiStore = new ApiStore();

const authStore = new AuthStore(apiStore.authApi, apiStore.userApi);

const calendarStore = new CalendarStore(authStore, apiStore.calendarApi, apiStore.userApi);

export const StoreContext = createContext({ apiStore, authStore, calendarStore });
