import { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { ExtendedAvailability } from "@parallel/vertex/types/calendar/availability.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import AvailabilityForm from "@/components/calendar/availability/AvailabilityForm";
import CommonDisplay from "@/components/shared/layout/CommonDisplay";
import { StoreContext } from "@/stores";
import { recurrenceText } from "@/util/calendar.util";

const AvailabilityDisplay = ({
  availability,
  onClose,
}: {
  availability: ExtendedAvailability;
  onClose: () => void;
}) => {
  const {
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) return <AvailabilityForm editing={availability} onClose={() => setIsEditing(false)} />;

  const fields = filterExists([
    currentUser?.userType === "ADMIN" && { text: availability.userName, icon: <AccountCircleIcon />, label: "User" },
    availability.recurrence && { text: recurrenceText(availability.recurrence), icon: <EventRepeatIcon /> },
  ]);

  const canEdit = calendarStore.canWriteCalendar(availability.startTime);

  return (
    <CommonDisplay
      title="Availability"
      record={availability}
      recordName={{ singular: "availability", plural: "availabilities" }}
      fields={fields}
      deleteRecord={mode => calendarStore.deleteAvailability(availability.availabilityId, mode)}
      onEdit={canEdit ? () => setIsEditing(true) : undefined}
      onClose={onClose}
    />
  );
};

export default AvailabilityDisplay;
